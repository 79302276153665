import { v4 as uuidv4 } from 'uuid';
import { getLocations } from 'domains/ActivityForm/apis/getLocations';
import { paginatedGet } from 'domains/ActivityForm/apis/paginatedGet';
import {
  Activity,
  Location,
  Program,
} from 'domains/ActivityForm/openapi-client/models';
import baseApi from './baseApi';

export const activityApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['Activities'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getActivities: builder.query<
        Activity[],
        {
          requestData: {
            siteId: number;
            programId?: number;
            pageSize?: number;
            pageNum?: number;
          };
        }
      >({
        query: ({ requestData }) => {
          const { siteId, programId, pageNum = 3, pageSize } = requestData;
          const url = `/api/schedule/v1/activities?siteId=${siteId}&programId=${programId}&pageNum=${pageNum}&pageSize=${pageSize}`;
          return {
            url: url,
            method: 'GET',
            headers: {
              'X-Request-ID': uuidv4(),
            },
          };
        },
        transformResponse: (activity: Activity[]) => {
          return activity;
        },
        providesTags: ['Activities'],
      }),

      getActivity: builder.query<
        Activity,
        { activityId?: number; siteId: number }
      >({
        query: ({ activityId, siteId }) => {
          const url = `/api/schedule/v1/activities/${activityId}?siteId=${siteId}`;
          return {
            url: url,
            method: 'GET',
            headers: {
              'X-Request-ID': uuidv4(),
            },
          };
        },
        providesTags: ['Activities'],
      }),
      getLocations: builder.query({
        queryFn: async ({
          programId,
          siteId,
        }: {
          programId: string;
          siteId: string;
        }) => {
          const programLocationsRequestData = {
            siteId,
            programId,
          };
          const data = await paginatedGet<Location>({
            apiRequest: getLocations,
            requestData: programLocationsRequestData,
          });
          return { data };
        },
      }),
      getProgram: builder.query<
        Program,
        {
          siteId: string | null;
          programId?: string;
        }
      >({
        query: ({ siteId, programId }) => {
          return {
            url: `/api/sites/${siteId}/programs/${programId}?include=preferences`,
            method: 'GET',
          };
        },
      }),
    }),
  });

export const {
  useGetActivitiesQuery,
  useGetActivityQuery,
  useGetLocationsQuery,
  useGetProgramQuery,
} = activityApi;
