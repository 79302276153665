"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StepperDrawer = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ds_ui_components_1 = require("@la/ds-ui-components");
var S = require("./StepperDrawer.styles");
var StepperDrawer = function (_a) {
    var stepNumber = _a.stepNumber, numberOfTotalSteps = _a.numberOfTotalSteps, onBackClick = _a.onBackClick, handleNextClick = _a.handleNextClick, type = _a.type, form = _a.form, isMC = _a.isMC;
    var getActionMessage = function () {
        if (stepNumber !== numberOfTotalSteps) {
            return 'Save and Continue';
        }
        if (isMC) {
            return 'Register and create invoices';
        }
        return 'Register now';
    };
    return ((0, jsx_runtime_1.jsx)(S.BottomDrawer, { children: (0, jsx_runtime_1.jsxs)(S.DrawerContent, { children: [(0, jsx_runtime_1.jsx)(S.BackButtonContainer, __assign({ "$disabled": stepNumber === 1 }, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Button, __assign({ variant: "outline", size: "xl", leftIcon: (0, jsx_runtime_1.jsx)(ds_ui_components_1.CaretLeftIcon, {}), disabled: stepNumber === 1, onClick: onBackClick }, { children: "Back" })) })), (0, jsx_runtime_1.jsx)(S.ForwardButtonContainer, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Button, __assign({ size: "xl", disabled: false, onClick: handleNextClick, type: type }, form, { children: getActionMessage() })) })] }) }));
};
exports.StepperDrawer = StepperDrawer;
