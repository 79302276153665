"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDivisionCardId = exports.ageGroupDisplay = exports.getSortedTeamsList = exports.DivisionCard = exports.DIVISION_CARD_ADD_TO_CART_ERROR_MESSAGE = exports.DISCARD_CONFIRMATION_DIALOG_CONFIRM_TEXT = exports.DISCARD_CONFIRMATION_DIALOG_TITLE = exports.teamFields = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var luxon_1 = require("luxon");
var ds_ui_components_1 = require("@la/ds-ui-components");
var utilities_1 = require("@la/utilities");
var hooks_1 = require("../../../../../lib/hooks");
var breakpoints_1 = require("../../../../../lib/media-queries/breakpoints");
var CreateTeamForm_1 = require("../CreateTeamForm/CreateTeamForm");
var DivisionCardFooter_1 = require("./DivisionCardFooter/DivisionCardFooter");
var DivisionCardHeader_1 = require("./DivisionCardHeader/DivisionCardHeader");
var DivisionCardInfoSection_1 = require("./DivisionCardInfoSection/DivisionCardInfoSection");
var DivisionCardInfoSectionRight_1 = require("./DivisionCardInfoSectionRight/DivisionCardInfoSectionRight");
var DivisionCardTeamSelectionSection_1 = require("./DivisionCardTeamSelectionSection/DivisionCardTeamSelectionSection");
var capacity_1 = require("./utils/capacity");
var S = require("./DivisionCard.styles");
exports.teamFields = ['name', 'organization'];
exports.DISCARD_CONFIRMATION_DIALOG_TITLE = 'Remove team';
exports.DISCARD_CONFIRMATION_DIALOG_CONFIRM_TEXT = 'Yes, remove this';
exports.DIVISION_CARD_ADD_TO_CART_ERROR_MESSAGE = 'Select "Save division" to save your current changes or "Cancel" to discard them.';
function DiscardConfirmationDialog(_a) {
    var isOpen = _a.isOpen, onOpenChange = _a.onOpenChange, onClearDivision = _a.onClearDivision, close = _a.close;
    var handleConfirmation = function () {
        onClearDivision();
        close();
    };
    return ((0, jsx_runtime_1.jsxs)(ds_ui_components_1.Modal, __assign({ open: isOpen, onOpenChange: onOpenChange, primaryAction: (0, jsx_runtime_1.jsx)(ds_ui_components_1.ModalPrimaryActionButton, __assign({ onClick: handleConfirmation }, { children: exports.DISCARD_CONFIRMATION_DIALOG_CONFIRM_TEXT })), tertiaryAction: (0, jsx_runtime_1.jsx)(ds_ui_components_1.ModalClose, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.ModalTertiaryActionButton, { children: "Cancel" }) }), size: "medium", title: exports.DISCARD_CONFIRMATION_DIALOG_TITLE }, { children: ["You are now removing this team and division from your registrations.", (0, jsx_runtime_1.jsx)(S.ConfirmationSentence, { children: "Are you sure you want to continue?" })] })));
}
function CreateTeamDialog(_a) {
    var maxDivisionAgeGroup = _a.maxDivisionAgeGroup, isOpen = _a.isOpen, onOpenChange = _a.onOpenChange, onTeamCreate = _a.onTeamCreate;
    var formRef = (0, react_1.useRef)(null);
    var _b = (0, react_1.useState)(false), isSubmitting = _b[0], setIsSubmitting = _b[1];
    var _c = (0, react_1.useState)(false), hasSubmitError = _c[0], setHasSubmitError = _c[1];
    var formId = 'create-team-form';
    /**
     * When a submission error occurs, the error is shown at the top of
     * the form in the modal's body. Beause the content of the body is
     * scrollable, we want to scroll the user to the top so they can
     * see it immediately.
     */
    (0, react_1.useEffect)(function () {
        var form = formRef.current;
        if (hasSubmitError && form && form.parentNode) {
            var formParentNode = form.parentNode;
            if (!!formParentNode.scrollTo) {
                formParentNode.scrollTo({
                    top: 0,
                });
            }
        }
    }, [hasSubmitError]);
    var onSubmit = function (team) {
        setHasSubmitError(false);
        setIsSubmitting(true);
        onTeamCreate(team)
            .then(function () { return setIsSubmitting(false); })
            .catch(function (error) {
            setIsSubmitting(false);
            if (error) {
                setHasSubmitError(true);
            }
        });
    };
    return ((0, jsx_runtime_1.jsx)(ds_ui_components_1.Modal, __assign({ open: isOpen, onOpenChange: onOpenChange, primaryAction: (0, jsx_runtime_1.jsx)(S.CreateTeamFormSubmitButton, __assign({ form: formId, loading: isSubmitting, type: "submit" }, { children: "Create team" })), tertiaryAction: (0, jsx_runtime_1.jsx)(ds_ui_components_1.ModalClose, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.ModalTertiaryActionButton, { children: "Cancel" }) }), size: "medium", title: "Create new team" }, { children: (0, jsx_runtime_1.jsx)(CreateTeamForm_1.CreateTeamForm, { ref: formRef, id: formId, defaultValues: { ageGroup: maxDivisionAgeGroup, country: 'USA' }, maxDivisionAgeGroup: maxDivisionAgeGroup, hasSubmitError: hasSubmitError, onSubmit: onSubmit }) })));
}
exports.DivisionCard = (0, react_1.forwardRef)(function (props, ref) {
    var division = props.division, showDates = props.showDates, showLocation = props.showLocation;
    var divisionId = division.id, name = division.name, ageGroup = division.ageGroup, gender = division.gender, cost = division.cost, experienceLevel = division.experienceLevel, location = division.location, startDate = division.startDate, endDate = division.endDate, registrationStartDate = division.registrationStartDate, registrationEndDate = division.registrationEndDate;
    var cardId = getDivisionCardId(division);
    var cardLabelId = "".concat(cardId, "-label");
    /*
     * Empty string means that no dialog is open
     */
    var _a = (0, react_1.useState)(''), dialogOpen = _a[0], setDialogOpen = _a[1];
    /**
     * Index of the select that triggered the `CreateTeamDialog`. Used to
     * populate the correct select with the newly created team.
     */
    var _b = (0, react_1.useState)(0), currentSelectIndex = _b[0], setCurrentSelectIndex = _b[1];
    var openCreateTeamDialog = function () {
        /*
         * When the dialog is opened via the "Enter" key, it will close immediately without this
         * setTimeout hack. I think the problem has something to do with event bubbling but I am
         * not sure. Unfortunately, the Select option that is being used to trigger the modal
         * opening doesn't give access to the event object to allow me to stop propagation.
         */
        setTimeout(function () {
            setDialogOpen('create-team');
        }, 0);
    };
    var openDiscardConfirmationDialog = function () {
        return setDialogOpen('discard-confirmation');
    };
    var closeDialog = function () { return setDialogOpen(''); };
    var handleOpenCreateTeamDialog = function (selectIndex) {
        openCreateTeamDialog();
        setCurrentSelectIndex(selectIndex);
    };
    // Single day event uses label "Date"
    var datesLabel = startDate === endDate ? 'Date' : 'Dates';
    var dates = (0, utilities_1.formatDateRange)(luxon_1.DateTime.fromISO(startDate), endDate ? luxon_1.DateTime.fromISO(endDate) : undefined);
    var isTabletLandscapeUp = (0, hooks_1.useMediaQuery)(breakpoints_1.breakpointQueries.tabletLandscapeUp);
    var variant = isTabletLandscapeUp
        ? 'regular'
        : 'dense';
    // Associate the division name as a label for all interactive content within the card.
    var cardProps = {
        'aria-labelledby': cardLabelId,
        role: 'group',
    };
    var header = ((0, jsx_runtime_1.jsx)(DivisionCardHeader_1.DivisionCardHeader, { cardLabelId: cardLabelId, cardState: props.cardState, cost: cost, name: name, numberOfSpotsLeft: (0, capacity_1.getNumberOfSpotsLeft)(division), registrationEndDate: registrationEndDate, registrationStartDate: registrationStartDate, variant: variant }));
    var isUnavailableDivision = function (props) {
        var cardState = props.cardState;
        return cardState.value === 'unavailable';
    };
    var info;
    var infoProps = {
        ageGroup: ageGroup ? ageGroupDisplay(ageGroup) : undefined,
        datesValue: showDates ? dates : undefined,
        datesLabel: datesLabel,
        endDate: endDate,
        experienceLevel: experienceLevel,
        gender: gender,
        location: showLocation ? location : undefined,
        startDate: startDate,
        variant: variant,
    };
    if (isUnavailableDivision(props) ||
        props.cardState.value === 'unavailable') {
        info = (0, jsx_runtime_1.jsx)(DivisionCardInfoSection_1.DivisionCardInfoSection, __assign({}, infoProps, { rightSection: null }));
    }
    else {
        var cardState_1 = props.cardState, onEditDivision = props.onEditDivision, onSelectDivision = props.onSelectDivision;
        info = ((0, jsx_runtime_1.jsx)(DivisionCardInfoSection_1.DivisionCardInfoSection, __assign({}, infoProps, { rightSection: (0, jsx_runtime_1.jsx)(DivisionCardInfoSectionRight_1.DivisionCardInfoRightSection, { cardState: cardState_1, onEditDivision: onEditDivision, onSelectDivision: onSelectDivision }) })));
    }
    var headerAndInfo = ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [header, info] }));
    if (isUnavailableDivision(props)) {
        return ((0, jsx_runtime_1.jsx)(S.UnavailableDivisionCard, __assign({ "data-testid": cardId }, cardProps, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Card, { children: headerAndInfo }) })));
    }
    var availableTeams = props.availableTeams, cardState = props.cardState, divisionCardsState = props.divisionCardsState, maxDivisionAgeGroup = props.maxDivisionAgeGroup, onAddTeamSelect = props.onAddTeamSelect, onClear = props.onClear, onCancel = props.onCancel, onDeleteTeam = props.onDeleteTeam, onDiscardTeamSelect = props.onDiscardTeamSelect, onSave = props.onSave, onSelectTeam = props.onSelectTeam, onTeamCreate = props.onTeamCreate, onUpdateTeam = props.onUpdateTeam, tournamentId = props.tournamentId, setHasDeleteTeamDivisionError = props.setHasDeleteTeamDivisionError, refetchTeams = props.refetchTeams;
    if (cardState.value === 'not-selected' || cardState.value === 'saved') {
        return ((0, jsx_runtime_1.jsx)(S.DivisionCard, __assign({ "data-testid": cardId }, cardProps, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Card, __assign({ variant: variant }, { children: headerAndInfo })) })));
    }
    var focusTeamSelect = function () {
        /**
         * Since the trigger for the modal is the "+ Create new team" option,
         * which is not shown once the dialog is open, focus would normally return
         * to the body. Thus, we need to use setTimeout in order to queue
         * the focus() call AFTER it focuses the body.
         */
        setTimeout(function () {
            var select = document.getElementById((0, DivisionCardTeamSelectionSection_1.getTeamSelectId)(divisionId, currentSelectIndex));
            select === null || select === void 0 ? void 0 : select.focus();
        }, 0);
    };
    var handleOpenCreateTeamDialogChange = function () {
        closeDialog();
        focusTeamSelect();
    };
    var handleTeamCreate = function (team) { return __awaiter(void 0, void 0, Promise, function () {
        var ageGroup, province, repName, repPhoneNumber, repEmail, teamName, state, restTeam, newTeam;
        return __generator(this, function (_a) {
            ageGroup = team.ageGroup, province = team.province, repName = team.repName, repPhoneNumber = team.repPhoneNumber, repEmail = team.repEmail, teamName = team.teamName, state = team.state, restTeam = __rest(team, ["ageGroup", "province", "repName", "repPhoneNumber", "repEmail", "teamName", "state"]);
            newTeam = __assign(__assign({}, restTeam), { name: teamName, admin1: province || state, ageGroup: ageGroup, status: 'DRAFT', teamRepresentative: repName && repPhoneNumber && repEmail
                    ? { name: repName, phoneNumber: repPhoneNumber, email: repEmail }
                    : undefined });
            return [2 /*return*/, onTeamCreate(divisionId, currentSelectIndex, newTeam).then(function () {
                    closeDialog();
                    focusTeamSelect();
                })];
        });
    }); };
    var teamsOptions = availableTeams.filter(function (team) {
        if (division.ageGroup) {
            return division.ageGroup >= team.ageGroup;
        }
        return true;
    });
    var teamsList = getSortedTeamsList(teamsOptions);
    var errorMessage;
    if (cardState.value !== 'unavailable' &&
        cardState.isShowingSubmissionErrorMessage) {
        errorMessage = exports.DIVISION_CARD_ADD_TO_CART_ERROR_MESSAGE;
    }
    return ((0, jsx_runtime_1.jsx)(S.DivisionCard, __assign({ "data-testid": cardId }, cardProps, { children: (0, jsx_runtime_1.jsxs)(ds_ui_components_1.Card, __assign({ hasError: !!errorMessage, errorMessage: errorMessage, ref: ref, variant: variant }, { children: [headerAndInfo, cardState.value === 'not-saved' ||
                    cardState.value === 'saved-and-editing' ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(DivisionCardTeamSelectionSection_1.DivisionCardTeamSelectionSection, { cardId: cardId, cardState: cardState, division: division, divisionCardsState: divisionCardsState, maxDivisionAgeGroup: maxDivisionAgeGroup, openCreateTeamDialog: handleOpenCreateTeamDialog, openDiscardConfirmationDialog: openDiscardConfirmationDialog, onAddTeamSelect: onAddTeamSelect, onClear: onClear, onDeleteTeam: onDeleteTeam, onDiscardTeamSelect: onDiscardTeamSelect, onSelectTeam: onSelectTeam, onUpdateTeam: onUpdateTeam, refetchTeams: refetchTeams, teamsList: teamsList, tournamentId: tournamentId, variant: variant, setHasDeleteTeamDivisionError: setHasDeleteTeamDivisionError }), (0, jsx_runtime_1.jsx)(DivisionCardFooter_1.DivisionCardFooter, { onCancel: onCancel, onSave: onSave })] })) : null, (0, jsx_runtime_1.jsx)(DiscardConfirmationDialog, { isOpen: dialogOpen === 'discard-confirmation', onOpenChange: closeDialog, onClearDivision: onClear, close: closeDialog }), (0, jsx_runtime_1.jsx)(CreateTeamDialog, { maxDivisionAgeGroup: maxDivisionAgeGroup, isOpen: dialogOpen === 'create-team', onOpenChange: handleOpenCreateTeamDialogChange, onTeamCreate: handleTeamCreate })] })) })));
});
/**
 * Returns an array containing the teams sorted first by age group (oldest
 * to youngest) and then alphabetically by name.
 * @param allTeams
 */
function getSortedTeamsList(allTeams) {
    return __spreadArray([], allTeams, true).sort(function (a, b) {
        if (a.ageGroup > b.ageGroup) {
            return -1;
        }
        else if (a.ageGroup < b.ageGroup) {
            return 1;
        }
        return a.name.localeCompare(b.name, 'en');
    });
}
exports.getSortedTeamsList = getSortedTeamsList;
/**
 * @param ageGroup The age group for a program or division
 * @returns A string to display for age group
 */
function ageGroupDisplay(ageGroup) {
    return "".concat(ageGroup, "u");
}
exports.ageGroupDisplay = ageGroupDisplay;
function getDivisionCardId(division) {
    return "reg-wizard-division-card-".concat(division.id);
}
exports.getDivisionCardId = getDivisionCardId;
