"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stepper = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ds_ui_components_1 = require("@la/ds-ui-components");
var Alert_1 = require("../Alert/Alert");
var StepperDrawer_1 = require("../StepperDrawer/StepperDrawer");
var S = require("./Stepper.styles");
var STEP_ERROR_MESSAGE = 'Please fix the errors below before continuing.';
var Stepper = function (_a) {
    var children = _a.children, error = _a.error, form = _a.form, handleNextClick = _a.handleNextClick, numberOfTotalSteps = _a.numberOfTotalSteps, stepNumber = _a.stepNumber, onBackClick = _a.onBackClick, type = _a.type, showDrawer = _a.showDrawer, currentStep = _a.currentStep, showSteps = _a.showSteps, _b = _a.isMC, isMC = _b === void 0 ? false : _b;
    return ((0, jsx_runtime_1.jsxs)(S.RegistrationContent, { children: [error ? ((0, jsx_runtime_1.jsx)(Alert_1.Alert, { icon: (0, jsx_runtime_1.jsx)(ds_ui_components_1.ExclamationOctagonIcon, { size: "xl", fill: "var(--red)" }), message: STEP_ERROR_MESSAGE })) : null, showSteps ? ((0, jsx_runtime_1.jsx)(S.StepContainer, { children: (0, jsx_runtime_1.jsxs)(ds_ui_components_1.Typography, __assign({ variant: "headline", size: "small" }, { children: ["Step ", stepNumber, " of ", numberOfTotalSteps, ": ", currentStep] })) })) : null, children, showDrawer ? ((0, jsx_runtime_1.jsx)(StepperDrawer_1.StepperDrawer, { form: form, handleNextClick: handleNextClick, numberOfTotalSteps: numberOfTotalSteps, stepNumber: stepNumber, onBackClick: onBackClick, type: type, isMC: isMC })) : null] }));
};
exports.Stepper = Stepper;
