import { Item } from '@radix-ui/react-radio-group';
import styled from 'styled-components';
import { getComputedIndicatorMargin, indicatorSizes, labelPaddings, variantSizes } from './RadioButton.utils';
export const RadioButtonContainer = styled.div.withConfig({
  componentId: "sc-4aahur-0"
})(["display:flex;align-items:center;&[data-disabled]{cursor:not-allowed;}"]);
export const RadioButtonItem = styled(Item).withConfig({
  componentId: "sc-4aahur-1"
})(["height:", ";width:", ";border-radius:50%;border:1px solid;border-color:", ";background-color:white;cursor:pointer;outline-width:2px;outline-offset:3px;position:relative;overflow:hidden;padding:0;&:focus{outline-color:", ";}&:hover{border-color:", ";}&[data-state='checked']{border-color:", ";&:after{content:' ';position:absolute;height:", "px;width:", "px;margin:", ";border-radius:50%;top:50%;left:50%;background-color:", ";}&:not([data-disabled]){&:hover{border-color:", ";}&:active{border-color:", ";}&:hover:after{background-color:", ";}&:active:after{background-color:", ";}}}&[data-disabled]{border-color:var(--secondary-200);pointer-events:none;& ~ div:has(label){cursor:not-allowed;& > label{pointer-events:none;color:var(--secondary-200);}}}&[data-disabled]:after{background-color:var(--secondary-200);pointer-events:none;}"], ({
  $size
}) => variantSizes[$size], ({
  $size
}) => variantSizes[$size], ({
  $error
}) => !$error ? 'var(--radio-button-border-color-default, var(--secondary-400))' : 'var(--radio-button-border-color-error, var(--red-500))', ({
  $error
}) => !$error ? 'var(--primary-400)' : 'var(--red-400)', ({
  $error
}) => !$error ? 'var(--primary-600)' : 'var(--red-700)', ({
  $error
}) => !$error ? 'var(--primary-600)' : 'var(--red-500)', ({
  $size
}) => indicatorSizes[$size], ({
  $size
}) => indicatorSizes[$size], ({
  $size
}) => getComputedIndicatorMargin($size), ({
  $error
}) => !$error ? 'var(--primary-600)' : 'var(--red-500)', ({
  $error
}) => !$error ? 'var(--primary-800)' : 'var(--red-700)', ({
  $error
}) => !$error ? 'var(--primary-900)' : 'var(--red-800)', ({
  $error
}) => !$error ? 'var(--primary-800)' : 'var(--red-700)', ({
  $error
}) => !$error ? 'var(--primary-900)' : 'var(--red-800)');
export const RadioButtonLabel = styled.label.withConfig({
  componentId: "sc-4aahur-2"
})(["cursor:pointer;font-size:", ";margin-left:", ";font-weight:var(--ui-weight-regular);color:var(--secondary-900);}"], ({
  $size
}) => `var(--ui-size-${$size})`, ({
  $size
}) => labelPaddings[$size]);