"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseWizard = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var ds_ui_components_1 = require("@la/ds-ui-components");
var ErrorCard_1 = require("../../components/ErrorCard/ErrorCard");
var MainContent_1 = require("../../components/MainContent/MainContent");
var TeamRegistrationContext_1 = require("./TeamRegistration/TeamRegistrationContext");
var Wizard_1 = require("./Wizard/Wizard");
var constants_1 = require("./constants");
var useTournamentForRegistration_1 = require("./hooks/useTournamentForRegistration");
var Registration_styles_1 = require("./Registration.styles");
var Wizard_styles_1 = require("./Wizard/Wizard.styles");
var BaseWizard = function (_a) {
    var setWizardState = _a.setWizardState, saveDivisions = _a.saveDivisions, stepForward = _a.stepForward;
    var tournamentId = (0, react_router_dom_1.useParams)().tournamentId;
    var _b = (0, react_1.useContext)(TeamRegistrationContext_1.TeamRegistrationContext), state = _b.state, setDivisions = _b.setDivisions;
    var _c = (0, useTournamentForRegistration_1.useTournamentForRegistration)({
        id: tournamentId,
        payerId: state.payer.id.toString(),
    }), tournament = _c.tournament, isTournamentLoading = _c.isLoading, tournamentError = _c.error;
    (0, react_1.useEffect)(function () {
        if (tournament && !state.divisions) {
            setDivisions(tournament.divisions);
        }
    }, [tournament, state, setDivisions]);
    if (isTournamentLoading) {
        return ((0, jsx_runtime_1.jsx)(MainContent_1.MainContentCenter, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Loader, { description: "We are gathering payer's registrations...", loading: true }) }));
    }
    if (tournamentError) {
        return ((0, jsx_runtime_1.jsx)(MainContent_1.MainContent, { children: (0, jsx_runtime_1.jsx)(Wizard_styles_1.WizardContent, { children: (0, jsx_runtime_1.jsx)(Registration_styles_1.RegistrationErrorContainer, { children: (0, jsx_runtime_1.jsx)(ErrorCard_1.ErrorCard, { message: constants_1.API_ERROR_MESSAGE }) }) }) }));
    }
    var hasRegistrationsToEdit = !!(tournament === null || tournament === void 0 ? void 0 : tournament.divisions.find(function (division) {
        var _a;
        return ((_a = division.registrationsForUser) === null || _a === void 0 ? void 0 : _a.team) &&
            division.registrationsForUser.team.find(function (registration) { return registration.registrationStatus === 'CREATED'; });
    }));
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: hasRegistrationsToEdit ? ((0, jsx_runtime_1.jsx)(Wizard_1.Wizard, { hasRegistrationsToEdit: hasRegistrationsToEdit, sectionTitle: "Edit your divisions and teams", submitButtonText: "Update cart", submitUnsavedChangesErrorMessage: "Registrations were not updated in the cart. Unsaved changes must be saved or discarded.", tournament: tournament, saveDivisions: saveDivisions, stepForward: stepForward, setWizardState: setWizardState, isMC: true })) : ((0, jsx_runtime_1.jsx)(Wizard_1.Wizard, { hasRegistrationsToEdit: hasRegistrationsToEdit, sectionTitle: "Add your divisions and teams", submitButtonText: "Add to cart", submitUnsavedChangesErrorMessage: "Registrations were not added to the cart. Unsaved changes must be saved or discarded.", tournament: tournament, saveDivisions: saveDivisions, stepForward: stepForward, setWizardState: setWizardState, isMC: true })) }));
};
exports.BaseWizard = BaseWizard;
