import { ReactNode } from 'react';
import { DateTime } from 'luxon';
import { CalendarIcon, CardProps, MapPinIcon } from '@la/ds-ui-components';
import { genderNameMap } from 'lib/utils/utilities';
import { Division } from 'redux/services/types/tournament';
import { DivisionCardSection } from '../DivisionCard.styles';
import * as S from './DivisionCardInfoSection.styles';

export const ACTIVITY_DATE_FORMAT = 'M/d/yyyy';

type DivisionCardInfoSectionProps = Pick<
  Division,
  'endDate' | 'experienceLevel' | 'gender' | 'location' | 'startDate'
> &
  Pick<CardProps, 'variant'> & {
    ageGroup?: string;
    datesValue?: string;
    datesLabel: string;
    rightSection: ReactNode;
  };

export function DivisionCardInfoSection({
  startDate,
  endDate,
  ageGroup,
  datesValue,
  datesLabel,
  experienceLevel,
  gender,
  location,
  rightSection,
  variant,
}: DivisionCardInfoSectionProps) {
  const activityDates = formatActivityDates(startDate, endDate);

  return (
    <DivisionCardSection $variant={variant}>
      <S.DivisionCardPrimaryInfoContainer>
        <S.DivisionCardPrimaryInfo>
          {ageGroup ? (
            <S.DivisionCardInfo data-testid="reg-wiz-division-card-age-group-info">
              <S.DivisionCardInfoLabel>Age group:</S.DivisionCardInfoLabel>{' '}
              <S.DivisionCardInfoValue>{ageGroup}</S.DivisionCardInfoValue>
            </S.DivisionCardInfo>
          ) : null}
          {experienceLevel ? (
            <S.DivisionCardInfo data-testid="regWizard-divisionCard-experienceLevel">
              <S.DivisionCardInfoLabel>
                {experienceLevel.label}:
              </S.DivisionCardInfoLabel>{' '}
              <S.DivisionCardInfoValue>
                {experienceLevel.value}
              </S.DivisionCardInfoValue>
            </S.DivisionCardInfo>
          ) : null}
          {gender ? (
            <S.DivisionCardInfo data-testid="reg-wiz-division-card-gender-info">
              <S.DivisionCardInfoLabel>Gender:</S.DivisionCardInfoLabel>{' '}
              <S.DivisionCardInfoValue>
                {genderNameMap[gender]}
              </S.DivisionCardInfoValue>
            </S.DivisionCardInfo>
          ) : null}
          {datesValue ? (
            <S.DivisionCardInfo data-testid="reg-wiz-division-card-date-info">
              <S.DivisionCardInfoLabel>{datesLabel}:</S.DivisionCardInfoLabel>{' '}
              <S.DivisionCardInfoValue>{datesValue}</S.DivisionCardInfoValue>
            </S.DivisionCardInfo>
          ) : null}
        </S.DivisionCardPrimaryInfo>
        {rightSection}
      </S.DivisionCardPrimaryInfoContainer>
      <S.DivisionCardSecondaryInfoContainer>
        {location ? (
          <S.DivisionCardSecondaryInfo data-testid="regWizard-divisionCard-location">
            <MapPinIcon aria-label="location" size="medium" variant="filled" />
            <S.DivisionCardSecondaryInfoValue>
              {location.name}
            </S.DivisionCardSecondaryInfoValue>
          </S.DivisionCardSecondaryInfo>
        ) : null}
        <S.DivisionCardSecondaryInfo data-testid="regWizard-divisionCard-activityDates">
          <CalendarIcon aria-label="" size="medium" variant="filled" />
          <S.DivisionCardSecondaryInfoValue>
            {activityDates}
          </S.DivisionCardSecondaryInfoValue>
        </S.DivisionCardSecondaryInfo>
      </S.DivisionCardSecondaryInfoContainer>
    </DivisionCardSection>
  );
}

export function formatActivityDates(
  startDate: string,
  endDate?: string
): string {
  const formattedStartDate =
    DateTime.fromISO(startDate).toFormat(ACTIVITY_DATE_FORMAT);
  const formattedEndDate = endDate
    ? DateTime.fromISO(endDate).toFormat(ACTIVITY_DATE_FORMAT)
    : null;

  return `${formattedStartDate}${
    formattedEndDate ? ` - ${formattedEndDate}` : ''
  }`;
}
