import styled from 'styled-components/macro';
import { Form, ThemedComponent } from '@la/ds-ui-components';

export const PaymentTermsModalItems = styled(Form)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 32px;
`;

export const PaymentTermsModalItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

type PaymentTermsModalRowProps = ThemedComponent<{ $showDivider?: boolean }>;
export const PaymentTermsModalRow = styled.div<PaymentTermsModalRowProps>(
  ({ $showDivider, theme }: PaymentTermsModalRowProps) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: $showDivider ? '4px' : 0,
    paddingBottom: $showDivider ? '4px' : 0,
    borderBottom: $showDivider
      ? `1px solid ${theme.semantic?.primary[100]}`
      : 'none',
  })
);

export const PaymentTermsModalItemHeader = styled(PaymentTermsModalRow)`
  flex-direction: row;
  justify-content: space-between;
`;

export const PaymentTermModalItemPaymentPlans = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    input: {
      cursor: 'pointer',
    },

    label: {
      ...theme.typography.ui.large,
      color: theme.foundational.blueGrey[900],
    },
  })
);

export const PaymentTermModalItemRadioButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const PaymentTermModalItemDetailsButton = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    marginLeft: 'auto',

    '&, &:hover, &:active': {
      span: {
        background: 'none',

        svg: {
          fill: theme.semantic?.secondary[600],
        },
      },
    },
  })
);

export const PaymentPlanDetailsSchedule = styled.div`
  margin: 16px 0;

  th:last-child {
    text-align: right;
  }
`;

export const PayLaterCheckbox = styled.div`
  display: flex;
  justify-content: flex-end;
`;
