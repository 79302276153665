"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PricePerTeam = exports.PartialAmountContainer = exports.TotalAmountContainer = exports.ReviewInvoicesContainer = void 0;
var styled_components_1 = require("styled-components");
exports.ReviewInvoicesContainer = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"])));
exports.TotalAmountContainer = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  justify-content: space-between;\n"])));
exports.PartialAmountContainer = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-top: 16px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-top: 16px;\n"])));
exports.PricePerTeam = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  justify-content: space-between;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
