import styled from 'styled-components/macro';
import { Card } from 'components/Card/Card';
import { media } from 'lib/media-queries/mixins';

export const TournamentDatesCard = styled(Card)`
  grid-column-start: 1;
  grid-column-end: -1;

  ${media.TabletLandscapeUp`
    grid-column-start: 6;
    grid-column-end: 12;
  `}
`;
