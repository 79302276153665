import * as Dialog from '@radix-ui/react-dialog';
import styled from 'styled-components';
import { convertHexadecimalToRGB } from 'theming/colorHandling';
import { breakpointQueries } from 'utilities/css/breakpoints';
const getModalBaseDimensions = size => {
  switch (size) {
    case 'extra-small':
      return {
        width: '48%',
        maxHeight: '48%'
      };
    case 'small':
      return {
        width: '70%',
        maxHeight: '54%'
      };
    case 'medium':
      return {
        width: '84%',
        maxHeight: '72%'
      };
    case 'large':
      return {
        width: '96%',
        maxHeight: '84%'
      };
    case 'fullscreen':
      return {
        height: '100%'
      };
  }
};
const getModalDesktopUpWidth = size => {
  switch (size) {
    case 'extra-small':
      return {
        width: '24%'
      };
    case 'small':
      return {
        width: '36%'
      };
    case 'medium':
      return {
        width: '48%'
      };
    case 'large':
      return {
        width: '72%'
      };
  }
};
export const Overlay = styled(Dialog.Overlay).withConfig({
  componentId: "sc-ikauyb-0"
})(({
  theme
}) => {
  const {
    blueGrey
  } = theme.palette.foundational;
  const backgroundColor = convertHexadecimalToRGB(blueGrey[900], 0.35);
  return {
    zIndex: 1000,
    position: 'fixed',
    inset: 0,
    backgroundColor
  };
});
export const ModalContent = styled(Dialog.Content).withConfig({
  componentId: "sc-ikauyb-1"
})(({
  theme,
  $maxHeight,
  $modalSize
}) => {
  const {
    spacing,
    palette
  } = theme;
  const {
    maxHeight,
    ...modalDimensions
  } = getModalBaseDimensions($modalSize);
  return {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    backgroundColor: palette.foundational.white,
    borderRadius: spacing.xxxs,
    // In mobile attach to bottom
    bottom: 0,
    width: '100%',
    maxHeight: $maxHeight ?? '100%',
    height: $modalSize === 'fullscreen' ? '100%' : undefined,
    // This prevents the children from covering the rounded corners
    overflow: 'hidden',
    [`@media ${breakpointQueries.tabletPortraitUp}`]: {
      // Center vertically and horizontally
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bottom: 'unset',
      maxHeight: $maxHeight ?? maxHeight,
      ...modalDimensions
    },
    [`@media ${breakpointQueries.desktopUp}`]: getModalDesktopUpWidth($modalSize)
  };
});