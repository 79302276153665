import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Button,
  EnvelopeIcon,
  PlusCircleIcon,
  Typography,
} from '@la/ds-ui-components';
import RosterTable, { Registrant } from './RosterTable';
import * as S from './RosterManagement.styles';

export type RosterSectionData = {
  openAddModal?: () => void;
  openModal: () => void;
  programId: string;
  rosterData: Registrant[];
  rosterTitle: string;
  rosterType: 'players' | 'staff';
  tableTitleSize: 'medium' | 'large';
  teamId: string;
};

/* RosterSection */
export default function RosterSection({
  openAddModal = () => {},
  openModal,
  programId,
  rosterData,
  rosterTitle,
  rosterType,
  tableTitleSize,
  teamId,
}: RosterSectionData) {
  const { registrationsRosterAddPlayer } = useFlags();

  const inviteType = rosterType === 'players' ? 'player' : 'staff';

  return (
    <S.RosterSection>
      <S.SectionHead>
        <Typography variant="headline" size={tableTitleSize}>
          {rosterTitle}
        </Typography>
        {inviteType === 'player' ? (
          <S.HeaderButtonsSection>
            {registrationsRosterAddPlayer ? (
              <Button
                leftIcon={
                  <PlusCircleIcon
                    fill="var(--secondary)"
                    size="xl"
                    variant="bold"
                  />
                }
                onClick={openAddModal}
                size="medium"
                variant="outline"
              >
                Add player
              </Button>
            ) : null}
            <Button
              leftIcon={
                <EnvelopeIcon
                  fill="var(--secondary)"
                  size="xl"
                  variant="bold"
                />
              }
              onClick={openModal}
              size="medium"
              variant="outline"
            >
              Invite players
            </Button>
          </S.HeaderButtonsSection>
        ) : null}
        {rosterData.length > 0 ? (
          <Button size="large" onClick={openModal}>
            Get {inviteType} invite link
          </Button>
        ) : null}
      </S.SectionHead>
      {rosterData.length > 0 ? (
        <RosterTable
          programId={programId}
          registrants={rosterData}
          tableType={rosterType}
          teamId={teamId}
        />
      ) : (
        <EmptyRosterNotice
          inviteType={inviteType}
          rosterType={rosterType}
          openModal={openModal}
        />
      )}
    </S.RosterSection>
  );
}
/* */

export function EmptyRosterNotice({
  inviteType,
  rosterType,
  openModal,
}: {
  inviteType: 'player' | 'staff';
  rosterType: 'players' | 'staff';
  openModal: () => void;
}) {
  return (
    <S.EmptyRosterNotice>
      <Typography variant="headline" size="small">
        You don't have any {rosterType} in your roster.
      </Typography>
      <Typography variant="ui" size="large" weight="regular">
        Get started by inviting {rosterType}.
      </Typography>
      <Button size="large" onClick={openModal}>
        Get {inviteType} invite link
      </Button>
    </S.EmptyRosterNotice>
  );
}
