import styled from 'styled-components';
export const CardWrapper = styled.div.withConfig({
  componentId: "sc-bkfpvs-0"
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px'
});
export const CardContainer = styled.div.withConfig({
  componentId: "sc-bkfpvs-1"
})(({
  theme,
  $width,
  $height,
  $hasError
}) => {
  const isDynamic = !!theme.semantic;
  const {
    blueGrey,
    red
  } = theme.foundational;
  const borderColor = isDynamic ? theme.semantic?.primary[300] : blueGrey[400];
  return {
    width: $width ?? 'auto',
    height: $height,
    backgroundColor: 'white',
    border: '1px solid',
    borderBottomWidth: $hasError ? '4px' : '1px',
    borderRadius: '4px',
    borderColor: $hasError ? red[500] : borderColor,
    overflow: 'hidden'
  };
});
export const CardHeader = styled.div.withConfig({
  componentId: "sc-bkfpvs-2"
})(({
  theme,
  $variant
}) => {
  const isDynamic = !!theme.semantic;
  const {
    blueGrey
  } = theme.foundational;
  const fontSize = $variant === 'regular' ? 'small' : 'xs';
  return {
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
    backgroundColor: isDynamic ? theme.semantic?.primary[35] : blueGrey[35],
    color: blueGrey[900],
    ...theme.typography.headline[fontSize]
  };
});
export const CardBody = styled.div.withConfig({
  componentId: "sc-bkfpvs-3"
})(({
  $variant
}) => {
  return {
    padding: $variant === 'dense' ? '16px' : '24px'
  };
});