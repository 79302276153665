import {
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
  useReducer,
} from 'react';
import { UploadedFile } from '@la/ds-ui-components';
import {
  FormField,
  FormFieldResponse,
  updateFormField,
} from 'lib/apis/getFormFields';
import { useGetRegistrationInfoFromFacade } from 'lib/apis/getRegistrantInfoFromFacade';
import { getStaffRoles } from 'lib/apis/getStaffRoles';
import { getExistingUserRegistration } from 'lib/apis/getUserRegistrations';
import {
  RegistrationWorkflowWaiver,
  formatFormFieldsForWorkflow,
  updateWorkflowRegistration,
} from 'lib/apis/updateWorkflowRegistration';
import { uploadFile as uploadFileApi } from 'lib/apis/uploadFile';
import { DecodedTokenData } from 'lib/apis/verifyUrlSignature';
import { GroupAccount, GroupAccountUser } from 'redux/services/groupAccountApi';
import { RegistrationOption } from 'redux/services/tournamentApi';
import { WaiverData } from 'domains/Checkout/Checkout.types';
import {
  RegistrantState,
  RegistrantStateActions,
  registrantInitialState,
  registrantReducer,
} from './reducer';
import { useRegistrantSteps } from './steps';

export type FieldState = {
  value: string;
  required: boolean;
  error?: string;
};

export type FormFieldUpdateData = {
  customFields: FormField[];
  registrationId?: string;
  userId?: string;
};

export type FileUploadData = {
  file: UploadedFile;
  propertyDefinitionId: string;
  userId: string;
};

export type RegistrationContextProps = RegistrantState & {
  dispatch: React.Dispatch<RegistrantStateActions>;
  decodedData: DecodedTokenData | null;
  formFields: FormFieldResponse;
  groupAccount: GroupAccount | null;
  loggedInUserId: number;
  masterProgramName?: string;
  memberFormFields: FormField[];
  teamName: string;
  tournamentId: string;
  hotelLink?: string;
  waivers: WaiverData[] | null;
  touched: boolean;
  setTouched: React.Dispatch<React.SetStateAction<boolean>>;
  waiversTouched: boolean;
  setWaiversTouched: React.Dispatch<React.SetStateAction<boolean>>;
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  updateFormFields: (data: FormFieldUpdateData) => Promise<{}[]>;
  uploadFile: (data: FileUploadData) => Promise<{ uuid: string }>;
  updateWithExistingRegistration: (userId: string) => Promise<void>;
  roleName?: string;
  registrationOptions: RegistrationOption[] | null;
  setRegistrationOptions: (options: RegistrationOption[]) => void;
};

const initialValues: RegistrationContextProps = {
  ...registrantInitialState,
  waivers: null,
  formFields: null,
  groupAccount: null,
  loggedInUserId: 0,
  memberFormFields: [],
  decodedData: null,
  tournamentId: '',
  masterProgramName: '',
  teamName: '',
  touched: false,
  setTouched: () => {},
  error: false,
  setError: () => {},
  dispatch: () => {},
  updateFormFields: async () => [],
  uploadFile: async () => ({ uuid: '' }),
  waiversTouched: false,
  setWaiversTouched: () => {},
  updateWithExistingRegistration: async () => {},
  registrationOptions: null,
  setRegistrationOptions: () => {},
};

export const RegistrationContext =
  createContext<RegistrationContextProps>(initialValues);

export const RegistrationProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [state, dispatch] = useReducer(
    registrantReducer,
    registrantInitialState
  );

  const [touched, setTouched] = useState(false);
  const [waiversTouched, setWaiversTouched] = useState(false);
  const [error, setError] = useState(false);
  const [roleName, setRoleName] = useState<string | undefined>();
  const [registrationOptions, setRegistrationOptions] = useState<
    RegistrationOption[] | null
  >(null);

  let encodedToken = window.location.search.substring(1);
  if (encodedToken) {
    localStorage.setItem('lip.invite-token', encodedToken);
  } else {
    encodedToken = localStorage.getItem('lip.invite-token') ?? '';
  }

  const {
    data,
    loading,
    error: registrationError,
  } = useGetRegistrationInfoFromFacade(encodedToken);

  useEffect(() => {
    dispatch({
      type: 'SET_GROUP_ACCOUNT',
      payload: data?.groupAccount ?? null,
    });
  }, [data]);

  useEffect(() => {
    if (data?.decodedData && !isNaN(parseInt(data?.decodedData.role))) {
      getStaffRoles({ siteId: data.decodedData.site })
        .then((staffRoles) => {
          const programStaffRole = staffRoles.find(
            (staffRole) => staffRole.id === parseInt(data.decodedData.role)
          )?.role;
          setRoleName(programStaffRole);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [data]);

  if (registrationError) {
    // This error message will be treated in another ticket
    throw new Error('Unable to fetch registrant data');
  }

  if (!data || loading) {
    return (
      <RegistrationContext.Provider value={initialValues}>
        {!data ? null : children}
      </RegistrationContext.Provider>
    );
  }

  const {
    decodedData,
    formFields,
    loggedInUserId,
    masterProgram,
    memberFormFields,
    team,
    waivers,
  } = data;

  const updateWithExistingRegistration = async (userId: string) => {
    return getExistingUserRegistration({
      registrationType: decodedData.type === 'player' ? 'PLAYER' : 'STAFF',
      userId: parseInt(userId),
      programId: parseInt(decodedData.prid),
      teamIdOg: parseInt(decodedData.team),
      roleId: !isNaN(parseInt(decodedData.role))
        ? parseInt(decodedData.role)
        : undefined,
    }).then((existingRegistration) => {
      if (existingRegistration && existingRegistration.metadata) {
        if (existingRegistration.registrationStatus === 'REGISTERED') {
          const message =
            existingRegistration.registrationType === 'PLAYER'
              ? 'This player already has a completed registration. Please select a different player.'
              : 'You have already registered for this tournament.';
          dispatch({
            type: 'SET_EXISTING_REGISTRATION_ERROR',
            payload: message,
          });

          return;
        }
        dispatch({ type: 'SET_EXISTING_REGISTRATION_ERROR', payload: '' });

        const { formFields: existingFormFields, waivers: existingWaivers } =
          existingRegistration.metadata;

        if (existingFormFields && existingFormFields.length) {
          existingFormFields.forEach((formField) => {
            const { formFieldId, type, values } = formField;

            if (type === 'FILE_UPLOAD') {
              const currentFormField = state.fileUploadFormFields[formFieldId];
              if (currentFormField) {
                dispatch({
                  type: 'SET_FILE_UPLOAD_FORM_FIELD',
                  payload: {
                    id: formFieldId,
                    field: {
                      ...currentFormField,
                      value: {
                        file: null,
                        name: values[0],
                        uuid: values[1],
                      },
                    },
                  },
                });
              }
            } else {
              const currentFormField =
                state.nonFileUploadFormFields[formFieldId];
              if (currentFormField) {
                dispatch({
                  type: 'SET_NON_FILE_UPLOAD_FORM_FIELD',
                  payload: {
                    id: formFieldId,
                    field: {
                      ...currentFormField,
                      value:
                        type === 'MULTIPLE_CHECKBOXES' ? values : values[0],
                    } as any,
                  },
                });
              }
            }
          });
        }

        if (existingWaivers && existingWaivers.length) {
          existingWaivers.forEach((waiver) => {
            if (waivers) {
              const currentWaiver = waivers[waiver.waiverId];
              if (currentWaiver) {
                dispatch({
                  type: 'SET_SIGNED_WAIVERS',
                  payload: {
                    id: waiver.waiverId.toString(),
                    signed: true,
                  },
                });
              }
            }
          });
        }
      }
    });
  };

  const uploadFile = ({
    file,
    propertyDefinitionId,
    userId,
  }: FileUploadData): Promise<{ uuid: string }> => {
    return uploadFileApi({
      siteId: decodedData?.site.toString() ?? '',
      propertyDefinitionId,
      userId,
      file: file.file as ArrayBuffer,
      filename: file.name,
    });
  };

  const updateFormFields = ({
    customFields,
    registrationId,
    userId,
  }: FormFieldUpdateData): Promise<{}[]> => {
    return Promise.all(
      customFields.map((field) => {
        const { propertyDefinitionId, type, value } = field;
        // Do not make call to update form field if there is no value
        if (!value) {
          return {};
        }

        let values;
        switch (type) {
          case 'FILE_UPLOAD':
            let uuid = value.uuid;
            if (uuid) {
              values = [
                {
                  registrationId,
                  userId,
                  value: uuid,
                },
              ];
            } else {
              // If the form is not uploaded yet (`uuid` is undefined), upload
              // the file first and then set `values` accordingly.
              if (userId) {
                return uploadFile({
                  file: { file: value.file, name: value.name },
                  propertyDefinitionId: propertyDefinitionId.toString(),
                  userId: userId,
                }).then(({ uuid }) => {
                  // TODO: Remove once we have tested the full flow
                  console.log('uuid', uuid);

                  let id = registrationId ? { registrationId } : { userId };

                  return updateFormField({
                    siteId: decodedData?.site,
                    formFieldId: propertyDefinitionId.toString(),
                    values: [
                      {
                        ...id,
                        value: uuid,
                      },
                    ],
                  });
                });
              }

              throw new Error('A user id must be supplied to upload a file.');
            }
            break;
          case 'MULTIPLE_CHECKBOXES':
            values = value.map((itemId) => ({
              registrationId,
              userId,
              value: itemId,
            }));
            break;
          default:
            values = [
              {
                registrationId,
                userId,
                value,
              },
            ];
            break;
        }

        return updateFormField({
          siteId: decodedData?.site,
          formFieldId: propertyDefinitionId.toString(),
          values,
        });
      })
    );
  };

  return (
    <RegistrationContext.Provider
      value={{
        ...state,
        dispatch,
        waivers,
        formFields,
        decodedData,
        loggedInUserId,
        memberFormFields,
        tournamentId: decodedData.prid,
        masterProgramName: masterProgram.name,
        hotelLink: masterProgram.details.hotelLinks,
        teamName: team.name,
        touched,
        setTouched,
        updateFormFields,
        uploadFile,
        error,
        setError,
        waiversTouched,
        setWaiversTouched,
        updateWithExistingRegistration,
        roleName,
        registrationOptions,
        setRegistrationOptions,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};

export const useRegistration = () => {
  const context = useContext(RegistrationContext);
  const {
    currentStep,
    formSteps,
    stepNumber,
    numberOfTotalSteps,
    getNextStep,
    getPreviousStep,
    onNextClick,
    onBackClick,
    steps,
  } = useRegistrantSteps(context.decodedData?.type, context.formFields);

  const childPlayers: GroupAccountUser[] = (context.groupAccount?.members ?? [])
    .filter((member) => member.user.type === 'child')
    .map((member) => member.user);

  const {
    decodedData,
    nonFileUploadFormFields,
    fileUploadFormFields,
    loggedInUserId,
    selectedPlayer,
    signedWaivers,
  } = context;

  const onWaiversSubmit = () => {
    if (!context.waivers) {
      return null;
    }

    context.setWaiversTouched(true);
    let hasError = false;

    for (const waiver of context.waivers) {
      if (!context.signedWaivers[waiver.waiverId]) {
        hasError = true;
      }
    }

    context.setError(hasError);

    if (!hasError && decodedData) {
      const registrationFormFields = formatFormFieldsForWorkflow(
        [
          Object.values(nonFileUploadFormFields),
          Object.values(fileUploadFormFields),
        ].flat()
      );

      const registrationWaivers: RegistrationWorkflowWaiver[] = Object.keys(
        signedWaivers
      ).map((waiverId) => ({
        waiverId: parseInt(waiverId),
        waiverType: 'REGISTRATION' as 'REGISTRATION',
      }));

      updateWorkflowRegistration({
        siteId: decodedData.site,
        registrationType:
          decodedData.type === 'player' ? 'PLAYER' : decodedData.role,
        registeredUserId:
          decodedData.type === 'player'
            ? parseInt(selectedPlayer)
            : loggedInUserId,
        registeringUserId: loggedInUserId,
        programId: parseInt(decodedData.prid),
        teamIdOg: parseInt(decodedData.team),
        formFields: registrationFormFields,
        waivers: registrationWaivers,
      }).then(onNextClick);
    }
  };

  const submitRegistration = () => {
    if (decodedData) {
      const registrationFormFields = formatFormFieldsForWorkflow(
        [
          Object.values(nonFileUploadFormFields),
          Object.values(fileUploadFormFields),
        ].flat()
      );

      const registrationWaivers: RegistrationWorkflowWaiver[] = Object.keys(
        signedWaivers
      ).map((waiverId) => ({
        waiverId: parseInt(waiverId),
        waiverType: 'REGISTRATION' as 'REGISTRATION',
      }));

      updateWorkflowRegistration(
        {
          siteId: decodedData.site,
          registrationType:
            decodedData.type === 'player' ? 'PLAYER' : decodedData.role,
          registeredUserId:
            decodedData.type === 'player'
              ? parseInt(selectedPlayer)
              : loggedInUserId,
          registeringUserId: loggedInUserId,
          programId: parseInt(decodedData.prid),
          teamIdOg: parseInt(decodedData.team),
          formFields: registrationFormFields,
          waivers: registrationWaivers,
        },
        true
      ).then(onNextClick);
    }
  };

  return {
    ...context,
    tournamentId: context.decodedData?.prid,
    siteId: context.decodedData?.site,
    childPlayers,
    currentStep,
    formSteps,
    stepNumber,
    numberOfTotalSteps,
    getNextStep,
    getPreviousStep,
    steps,
    onNextClick,
    onBackClick,
    onWaiversSubmit,
    submitRegistration,
  };
};
