import styled, { keyframes } from 'styled-components';
import { ThemedComponent } from '@la/ds-ui-components';
import { media } from 'lib/media-queries/mixins';

export const StyledForm = styled.form`
  margin: 20px 0;

  input[type='time'],
  input#date {
    margin: 4px 0;
  }

  ol {
    z-index: 10;
  }
`;

export const StyledFormSelectFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin: 20px 0;
`;

export const FormButtons = styled.div<{ $isEditMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;

  #discard {
    padding-left: 0;
  }

  ${({ $isEditMobile }) => {
    if ($isEditMobile) {
      return ` 
        flex-direction: column-reverse;
        align-items: flex-end;
      `;
    }
    return null;
  }}
`;

export const SecondaryButton = styled.div<{ $isEditMobile: boolean }>`
  display: flex;
  gap: 12px;

  ${({ $isEditMobile }) => {
    if ($isEditMobile) {
      return ` 
        justify-content: space-between;
        margin-bottom: 20px; 
        width: 100%; 
      `;
    }
    return null;
  }}
`;

export const Flex = styled.div`
  display: flex;
  gap: 16px;

  > div {
    flex: 1;
  }

  ${media.PhoneOnly`
    flex-direction: column;
  `}
`;

export const FlexEnd = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;

  > div {
    flex: 1;
  }
`;

export const RecurringDate = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  > div {
    flex: 1;
  }

  .repeatsFor {
    color: var(--blue-grey-600);
  }
`;

export const Label = styled.label<{ error?: boolean; disabled?: boolean }>`
  color: ${({ error, disabled }) => {
    if (error) {
      return 'var(--red-600)';
    } else if (disabled) {
      return 'var(--blue-grey-200)';
    }
    return 'var(--blue-grey-600)';
  }};
`;

export const BorderedFormSection = styled.div`
  border: 1px solid var(--blue-grey-400);
  border-radius: 6px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

export const RecurringSnackbarMessage = styled.div``;

export const CancelSection = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => {
    const { red, white } = theme.foundational;

    return {
      backgroundColor: red[25],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '10px',
      height: 60,
      padding: 12,
      '#cancel-button': {
        backgroundColor: red[600],
        borderColor: red[600],
        color: white,
      },
    };
  }
);

export const CancelText = styled.span<ThemedComponent>(
  ({ theme }: ThemedComponent) => {
    const { foundational, typography } = theme;

    return {
      ...typography.ui.medium,
      fontWeight: typography.weights.regular,
      color: foundational.blueGrey[900],
    };
  }
);

export const ActivityFormErrorMessage = styled.div`
  margin-top: 4px;
`;

const fadeIn = keyframes`
  0% {
    opacity:0;
    transform: scale(.75)
  }

  100% {
    opacity:1;
    transform: none;
  }
`;

export const WarningSnackbar = styled.div`
  width: auto;
  max-width: 760px;
  display: flex;

  div {
    background-color: #f8f3ef;
    border-color: #df6c06;
    border-width: 1px;
    border-style: solid;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 16px;
    opacity: 1;
    width: 100%;
    opacity: 1;
    animation: ${fadeIn} 100ms ease-out;
    transition:
      opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  p {
    margin-left: 8px;
  }
`;

export const StyledTextArea = styled.textarea<ThemedComponent>(({ theme }) => {
  return {
    ...theme.typography.ui.large,
    boxSizing: 'border-box',
    color: 'rgb(38, 50, 56)',
    backgroundColor: 'rgb(255, 255, 255)',
    border: '1px solid',
    borderRadius: '4px',
    borderColor: 'rgb(120, 144, 156)',
    padding: '4px 8px',
    cursor: 'pointer',
    maxWidth: '100%',
    maxHeight: '100%',
    resize: 'both',
    pointerEvents: 'auto',
    margin: '4px 0',
    width: '100%',

    '&:focus-within': {
      outline: 'rgb(120, 144, 156) solid 2px',
      outlineOffset: '2px',
    },
    '&::placeholder': {
      ...theme.typography.ui.large,
      color: 'rgb(120, 144, 156)',
      fontStyle: 'italic',
    },
  };
});
