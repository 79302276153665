import { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import {
  BillingSummaryRequestItem,
  CartItemData,
  PaymentPlanSummary,
} from '../Checkout.types';
import CheckoutCard, {
  CheckoutCardBody,
  CheckoutCardFooter,
  CheckoutCardPrimaryButton,
} from '../CheckoutCard/CheckoutCard';
import PaymentTermsModal, {
  PAY_IN_FULL_DETAIL,
  PAY_IN_FULL_VALUE,
  PaymentTermsFormFields,
} from '../PaymentTermsModal/PaymentTermsModal';
import * as S from './PaymentTermsCard.styles';

export type PaymentTermsItem = {
  itemId: string;
  payInFull?: boolean;
  paymentDetail: string;
  payLater?: boolean;
  paymentPlans: PaymentPlanSummary[];
  paymentTerm: string;
  subTotal: string;
  tournamentName: string;
};

export type PaymentTermsData = PaymentTermsItem[];

export type PaymentTermsCardProps = { cartItems: CartItemData[] };

/*PaymentTermsCard */
export default function PaymentTermsCard({ cartItems }: PaymentTermsCardProps) {
  const [isPaymentTermsModalOpen, setIsPaymentTermsModalOpen] = useState(false);

  const {
    selectedPaymentTerms,
    updateHasAutopayPaymentPlanSelected,
    updateSelectedPaymentTerms,
  } = useCheckoutInfo();

  const paymentTermsData = cartItems.map(
    (item) => buildPaymentTermsData(item, selectedPaymentTerms),
    []
  );

  const { cartCheckoutPaymentTerms } = useFlags();

  const updateSelectedPaymentTermsData = (
    paymentTermsFormFields: PaymentTermsFormFields
  ): void => {
    const paymentPlans = cartItems.flatMap(
      (cartItem) => cartItem.options.program.paymentPlanSummaries ?? []
    );

    let hasAutopayPaymentPlanSelected = false;

    const paymentTerms = Object.entries(
      paymentTermsFormFields
    ).map<BillingSummaryRequestItem>(([cartItemUuid, option]) => {
      const paymentPlan = paymentPlans.find(
        (paymentPlan) => paymentPlan.ngPaymentPlanId === option.paymentTerm
      );
      const autopayRequired = !!paymentPlan?.autopayRequired;

      if (option.paymentTerm !== 'FULL' && paymentPlan && autopayRequired) {
        hasAutopayPaymentPlanSelected = true;
      }

      return {
        autopayRequired,
        cartItemUuid,
        payLater: option.payLater,
        paymentTerm: option.paymentTerm === 'FULL' ? 'FULL' : 'PAYMENT_PLAN',
        paymentPlanId:
          option.paymentTerm === 'FULL' ? undefined : option.paymentTerm,
      };
    });

    updateSelectedPaymentTerms(paymentTerms);
    updateHasAutopayPaymentPlanSelected(hasAutopayPaymentPlanSelected);
    setIsPaymentTermsModalOpen(false);
  };

  return (
    <>
      <CheckoutCard cardName="Payment terms">
        <CheckoutCardBody>
          <PaymentTermsBody paymentTermsData={paymentTermsData} />
        </CheckoutCardBody>
        {cartCheckoutPaymentTerms && (
          <CheckoutCardFooter>
            <CheckoutCardPrimaryButton
              label="Edit payment terms"
              clickHandler={() => setIsPaymentTermsModalOpen(true)}
            />
          </CheckoutCardFooter>
        )}
      </CheckoutCard>
      <PaymentTermsModal
        onSubmit={updateSelectedPaymentTermsData}
        open={isPaymentTermsModalOpen}
        closeModal={() => setIsPaymentTermsModalOpen(false)}
        paymentTermsData={paymentTermsData}
      />
    </>
  );
}

function buildPaymentTermsData(
  cartItem: CartItemData,
  selectedPaymentTerms?: BillingSummaryRequestItem[]
): PaymentTermsItem {
  const tournamentName = cartItem.options.program.programName;
  const subTotal = cartItem.subtotal;

  const paymentPlans = cartItem.options.program.paymentPlanSummaries ?? [];
  const payInFull =
    cartItem.options.program.paymentRules?.payInFull ||
    paymentPlans.length === 0;
  const selectedPaymentTerm = selectedPaymentTerms?.find(
    (item) => item.cartItemUuid === cartItem.cartItemUuid
  );

  let paymentTerm, paymentDetail;
  if (
    selectedPaymentTerm &&
    selectedPaymentTerm.paymentTerm !== 'FULL' &&
    selectedPaymentTerm.paymentPlanId
  ) {
    const { paymentPlanId } = selectedPaymentTerm;
    paymentTerm = paymentPlanId;
    paymentDetail =
      paymentPlans.find(
        (paymentPlan) => paymentPlan.ngPaymentPlanId === paymentPlanId
      )?.name ?? '';
  } else if (!selectedPaymentTerm && !payInFull && paymentPlans.length) {
    const firstPaymentPlan = paymentPlans[0];
    paymentTerm = firstPaymentPlan.ngPaymentPlanId;
    paymentDetail = firstPaymentPlan.name;
  } else {
    paymentTerm = PAY_IN_FULL_VALUE;
    paymentDetail = PAY_IN_FULL_DETAIL;
  }

  return {
    itemId: cartItem.cartItemUuid,
    payInFull,
    payLater: selectedPaymentTerm?.payLater,
    paymentPlans,
    paymentDetail,
    paymentTerm,
    subTotal,
    tournamentName,
  };
}

/* */

/*PaymentTermsBody */
function PaymentTermsBody({
  paymentTermsData,
}: {
  paymentTermsData: PaymentTermsData;
}) {
  return (
    <S.PaymentTermsBody>
      <PaymentTermsItems paymentTermsData={paymentTermsData} />
    </S.PaymentTermsBody>
  );
}
/* */

/*PaymentTermsItems */
function PaymentTermsItems({
  paymentTermsData,
}: {
  paymentTermsData: PaymentTermsData;
}) {
  return (
    <>
      {paymentTermsData.map(({ tournamentName, paymentDetail }, idx) => (
        <S.PaymentTermsItem key={`paymentTermsItem-${idx}`}>
          <S.TournamentName>{tournamentName}</S.TournamentName>
          <S.PaymentDetail>{paymentDetail}</S.PaymentDetail>
        </S.PaymentTermsItem>
      ))}
    </>
  );
}
/* */
