import styled from 'styled-components';
import { DefaultTheme, ThemedComponent } from '@la/ds-ui-components';

export const TeamPrimaryStaffSelectWrapper = styled.div``;

type TeamOptionProps = {
  $noHover?: boolean;
  theme: DefaultTheme;
};

export const TeamOptionContainer = styled.div<TeamOptionProps>(
  ({ $noHover, theme }: TeamOptionProps) => {
    const { foundational } = theme;
    const backgroundHover = $noHover ? 'inherit' : foundational.blueGrey[50];

    return {
      cursor: 'pointer',
      display: 'grid',
      width: '100%',
      background: 'transparent',
      padding: '9px 16px',
      textAlign: 'left',
      '&[aria-selected="true"],:hover': {
        background: foundational.blueGrey[50],
      },
      ':hover': {
        background: backgroundHover,
      },
    };
  }
);

export const ExternalTeamOptionLink = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => {
    const { green } = theme.foundational;

    return {
      ...theme.typography.ui.medium,
      fontWeight: 500,
      textDecoration: 'underline',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      color: green[600],
    };
  }
);

export const AddNewTeamInput = styled.div`
  display: flex;
  align-items: end;
  gap: 12px;
  margin-top: 12px;

  > div:nth-child(1) {
    flex-grow: 1;
  }
`;

export const ExternalTeamOption = styled.div<{ $isDisabled?: boolean }>`
  padding: 0;
  > p {
    color: ${({ $isDisabled }) =>
      $isDisabled ? 'var(--blue-grey-250)' : 'var(--black)'};
  }
`;
