"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendRegistrationsForInvoice = exports.createRegistrationSummary = void 0;
var axios_1 = require("axios");
var utilities_1 = require("@la/utilities");
var getTournamentRegistrations_1 = require("./getTournamentRegistrations");
var baseUrl = (0, utilities_1.getBaseURL)();
var sendRegistrationsForInvoice = function (_a) {
    var state = _a.state, siteId = _a.siteId;
    return __awaiter(void 0, void 0, void 0, function () {
        var endpoint, token, registrations, payload, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    endpoint = "".concat(baseUrl, "/api/mgmt-console/").concat(siteId, "/registrations");
                    token = (0, utilities_1.getAccessToken)();
                    return [4 /*yield*/, (0, getTournamentRegistrations_1.getTournamentRegistrations)({
                            payerId: state.payer.id.toString(),
                            siteDomain: 'manager',
                            siteId: siteId,
                            tournamentId: state.wizardState.tournament.id,
                        })];
                case 1:
                    registrations = _b.sent();
                    payload = {
                        payingUserId: state.payer.id,
                        registrationSummary: (0, exports.createRegistrationSummary)(state, registrations),
                        siteId: parseInt(siteId),
                        version: 1,
                    };
                    return [4 /*yield*/, axios_1.default.post(endpoint, payload, {
                            headers: {
                                Authorization: "Bearer ".concat(token),
                            },
                        })];
                case 2:
                    response = _b.sent();
                    return [2 /*return*/, response.data];
            }
        });
    });
};
exports.sendRegistrationsForInvoice = sendRegistrationsForInvoice;
var createRegistrationSummary = function (state, registrations) {
    var divisions = registrations.divisions;
    var registrationsForUser = divisions
        .map(function (division) { return division.registrationsForUser.team; })
        .flat();
    var registrationsToInvoice = registrationsForUser.filter(function (registration) {
        return (registration.registeringUserId === state.payer.id.toString() &&
            registration.registrationStatus === 'CREATED');
    });
    return registrationsToInvoice.map(function (registration) {
        return {
            amount: state.wizardState.tournament.divisions
                .find(function (d) { return d.id === registration.programId; })
                .cost.toString(),
            externalRegistrationId: registration.id,
            ngPaymentPlanId: null,
            programId: parseInt(registration.programId),
            programStaffId: 14389,
            userId: state.payer.id,
            usingTeamPlayerPay: false,
            variableTeamFee: false,
        };
    });
};
exports.createRegistrationSummary = createRegistrationSummary;
