import { useEffect, useRef } from 'react';
import { Team } from 'redux/services/types/team';
import { Division, Location } from 'redux/services/types/tournament';
import { DivisionCardsState } from 'domains/Tournaments/Registration/Wizard/Wizard.types';
import { DivisionCard } from 'domains/Tournaments/Registration/Wizard/components/DivisionCard/DivisionCard';
import { MIN_LOWER_AGE_GROUP } from '../CreateTeamForm/utils/ageGroup';
import * as S from './DivisionCards.styles';

export type DivisionCardsProps = {
  /**
   * The divisions to display - one card for each division
   */
  divisions: Division[];
  /**
   * An object used to lookup the current UI state of a division card
   */
  divisionCardsStateLookup: DivisionCardsState;
  /**
   * The ID of the division that needs to be scrolled to
   */
  divisionToScrollToId?: string;
  /**
   * Called when a division card is scrolled to.
   */
  onScrollToDivision: () => void;
  /**
   * Called to add a team to the list of options the user has when
   * adding a team to a division.
   * @param divisionId The id of the division
   * @param selectIndex The id of the select that the new team should be assigned to
   * @param team The new team
   */
  onTeamCreate: (
    divisionId: string,
    selectIndex: number,
    team: Omit<Team, 'id'>
  ) => Promise<void>;
  /**
   * Called when an unselected division is selected by the user.
   */
  onSelectDivision: (divisionId: string) => void;
  /**
   * Called when the current changes for a division are canceled.
   */
  onCancelDivisionChanges: (divisionId: string) => void;
  /**
   * Called when an additional team selection slot is added to a division.
   */
  onAddTeamSelect: (divisionId: string) => void;
  /**
   * Called when a team selection slot is discarded.
   */
  onDiscardTeamSelect: (divisionId: string, selectIndex: number) => void;
  /**
   * Called when a team is selected within a team selection slot.
   */
  onSelectTeam: (
    divisionId: string,
    selectIndex: number,
    teamId: string
  ) => void;
  /**
   * Called when a division being edited is saved.
   */
  onSaveDivision: (divisionId: string) => void;
  /**
   * Called when a saved division is going to be edited.
   */
  onEditDivision: (divisionId: string) => void;
  /**
   * Called when a saved division is removed, clearing the saved teams.
   */
  onClearDivision: (divisionId: string) => void;
  /**
   * The existing teams that can be selected for this tournament
   */
  teams: Team[];
  /**
   * The tournament start and end dates
   */
  tournamentDates: { start: string; end?: string };
  /**
   * Id of the tournament the divisions belong to
   */
  tournamentId: string;
  /**
   * The tournament location
   */
  tournamentLocation?: Location;
  /**
   * Setter called whenever a team cannot be deleted because is already tied to a registration.
   */
  setHasDeleteTeamDivisionError: (error: boolean) => void;
};

export function DivisionCards(props: DivisionCardsProps) {
  const {
    divisions,
    divisionCardsStateLookup,
    divisionToScrollToId,
    onAddTeamSelect,
    onCancelDivisionChanges,
    onClearDivision,
    onDiscardTeamSelect,
    onEditDivision,
    onSaveDivision,
    onSelectDivision,
    onSelectTeam,
    onScrollToDivision,
    onTeamCreate,
    teams,
    tournamentDates,
    tournamentId,
    tournamentLocation,
    setHasDeleteTeamDivisionError,
  } = props;

  const divisionToScrollToRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divisionToScrollToId) {
      if (divisionToScrollToRef.current) {
        divisionToScrollToRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        onScrollToDivision();
      }
    }
  }, [divisionToScrollToId, onScrollToDivision]);

  // If at least one division's dates differs from the tournament as a whole,
  // then we show the division dates in all the division cards.
  const showDivisionDates = divisions.some(
    (division) =>
      division.startDate !== tournamentDates.start ||
      division.endDate !== tournamentDates.end
  );

  const hasDivisionsWithDifferentLocationThanTournament = divisions.some(
    (division) => division.location?.id !== tournamentLocation?.id
  );

  const availableDivisions = divisions.filter((division) => {
    const cardState = divisionCardsStateLookup[division.id];
    return (
      cardState.value !== 'unavailable' ||
      (cardState.value === 'unavailable' &&
        cardState.reasonUnavailable === 'at-capacity')
    );
  });

  const upcomingDivisions = divisions.filter((division) => {
    const cardState = divisionCardsStateLookup[division.id];
    return (
      cardState.value === 'unavailable' &&
      cardState.reasonUnavailable === 'before-registration-period-opens'
    );
  });

  const pastDivisions = divisions.filter((division) => {
    const cardState = divisionCardsStateLookup[division.id];
    return (
      cardState.value === 'unavailable' &&
      cardState.reasonUnavailable === 'after-registration-period-ends'
    );
  });

  return (
    <S.DivisionCards>
      {availableDivisions.map((division) => {
        const divisionCardState = divisionCardsStateLookup[division.id];

        const addTeamSelect = () => {
          onAddTeamSelect(division.id);
        };
        const cancel = () => {
          onCancelDivisionChanges(division.id);
        };
        const clear = () => {
          onClearDivision(division.id);
        };
        const discardTeamSelect = (selectIndex: number) => {
          onDiscardTeamSelect(division.id, selectIndex);
        };
        const editDivision = () => {
          onEditDivision(division.id);
        };
        const save = () => {
          onSaveDivision(division.id);
        };
        const selectDivision = () => {
          onSelectDivision(division.id);
        };
        const selectTeam = (selectIndex: number, teamId: string) => {
          onSelectTeam(division.id, selectIndex, teamId);
        };

        const discardTeamSelects = (div: Division, teamId: string): void => {
          const cardState = divisionCardsStateLookup[div.id];
          const { value: cardStateValue } = cardState;

          if (
            cardStateValue === 'not-saved' ||
            cardStateValue === 'saved-and-editing'
          ) {
            // When a division is 'not-saved' or 'saved-and-editing', we
            // CLEAR the selection
            const { teamSelections } = cardState;
            const indexOfDeletedTeam = teamSelections.findIndex(
              (teamSelection) => teamSelection.teamId === teamId
            );
            if (indexOfDeletedTeam > -1) {
              onSelectTeam(div.id, indexOfDeletedTeam, '');
            }
          } else if (cardStateValue === 'saved') {
            // When a division is 'saved', we DISCARD that selection
            const { savedTeams } = cardState;
            const indexOfDeletedTeam = savedTeams.indexOf(teamId);
            if (indexOfDeletedTeam > -1) {
              onDiscardTeamSelect(div.id, indexOfDeletedTeam);
            }
          }
        };

        const discardDeletedTeamSelects = (teamId: string): void => {
          divisions.forEach((div) => {
            discardTeamSelects(div, teamId);
          });
        };

        const discardUpdatedTeamSelects = (
          teamId: string,
          teamAgeGroup: number
        ): void => {
          divisions.forEach((div) => {
            if (div.ageGroup && teamAgeGroup > div.ageGroup) {
              discardTeamSelects(div, teamId);
            }
          });
        };

        let maxDivisionAgeGroup = division.ageGroup ?? 0;

        const availableTeams =
          maxDivisionAgeGroup === 0
            ? teams
            : teams.filter(
                (team) =>
                  // Age group should be between the max and max minus MIN_LOWER_AGE_GROUP (inclusive)
                  team.ageGroup === 0 ||
                  (team.ageGroup <= maxDivisionAgeGroup &&
                    team.ageGroup >= maxDivisionAgeGroup - MIN_LOWER_AGE_GROUP)
              );

        return (
          <DivisionCard
            availableTeams={availableTeams}
            cardState={divisionCardState}
            division={division}
            divisionCardsState={divisionCardsStateLookup}
            key={division.id}
            maxDivisionAgeGroup={maxDivisionAgeGroup}
            onAddTeamSelect={addTeamSelect}
            onCancel={cancel}
            onClear={clear}
            onDeleteTeam={discardDeletedTeamSelects}
            onDiscardTeamSelect={discardTeamSelect}
            onEditDivision={editDivision}
            onSave={save}
            onSelectDivision={selectDivision}
            onSelectTeam={selectTeam}
            onTeamCreate={onTeamCreate}
            onUpdateTeam={discardUpdatedTeamSelects}
            ref={
              division.id === divisionToScrollToId
                ? divisionToScrollToRef
                : null
            }
            setHasDeleteTeamDivisionError={setHasDeleteTeamDivisionError}
            showDates={showDivisionDates}
            showLocation={hasDivisionsWithDifferentLocationThanTournament}
            tournamentId={tournamentId}
          />
        );
      })}

      {upcomingDivisions.length > 0 ? (
        <S.DivisionCardsSectionTitle>
          Upcoming registrations
        </S.DivisionCardsSectionTitle>
      ) : null}
      {upcomingDivisions.map((division) => {
        const divisionCardState = divisionCardsStateLookup[division.id];

        if (divisionCardState.value !== 'unavailable') {
          throw new Error('Upcoming division has invalid state.');
        }

        return (
          <DivisionCard
            cardState={divisionCardState}
            division={division}
            key={division.id}
            ref={
              division.id === divisionToScrollToId
                ? divisionToScrollToRef
                : null
            }
            showDates={showDivisionDates}
            showLocation={hasDivisionsWithDifferentLocationThanTournament}
          />
        );
      })}

      {pastDivisions.length > 0 ? (
        <S.DivisionCardsSectionTitle>
          Closed Registrations
        </S.DivisionCardsSectionTitle>
      ) : null}
      {pastDivisions.map((division) => {
        const divisionCardState = divisionCardsStateLookup[division.id];

        if (divisionCardState.value !== 'unavailable') {
          throw new Error('Past division has invalid state.');
        }

        return (
          <DivisionCard
            cardState={divisionCardState}
            division={division}
            key={division.id}
            ref={
              division.id === divisionToScrollToId
                ? divisionToScrollToRef
                : null
            }
            showDates={showDivisionDates}
            showLocation={hasDivisionsWithDifferentLocationThanTournament}
          />
        );
      })}
    </S.DivisionCards>
  );
}
