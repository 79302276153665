import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import checkoutReducer from 'redux/checkoutSlice';
import coreReducer from 'redux/coreSlice';
import userReducer from 'redux/userSlice';
import {
  loadStateFromLocalStorage,
  saveStateToLocalStorage,
} from './localStorage';
import baseApi from './services/baseApi';

export const store = configureStore({
  reducer: {
    core: coreReducer,
    checkout: checkoutReducer,
    user: userReducer,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  preloadedState: loadStateFromLocalStorage('app-store'),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware),
});

// NOTE: This will put information from the Redux store into localstorage
// which can be accessed even after a user has left the site if they don't
// log out. Be careful about exposing any Personal Identifiable Information
// (PII) and if you have any questions about the data being stored in
// localstorage this way clear it first with Infosec.
store.subscribe(
  // we use debounce to save the state once each 800ms
  // for better performances in case multiple changes occur in a short time
  debounce(() => {
    saveStateToLocalStorage('app-store', {
      //This limits which slices of the state we are serializing in localstorage
      checkout: store.getState().checkout,
    });
  }, 800)
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>(); // Export a hook that can be reused to resolve types
