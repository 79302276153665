import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ColumnGrid from 'components/ColumnGrid/ColumnGrid';
import {
  CheckoutInfoProvider,
  useCheckoutInfo,
} from 'lib/context/CheckoutInfoContext';
import { getSiteIdentityData } from 'redux/coreSlice';
import {
  useGetCartSummaryQuery,
  useGetPaymentWaiverQuery,
} from 'redux/services/checkoutApi';
import { useGetUserIdQuery } from 'redux/services/userInfo';
import { useAppSelector } from 'redux/store';
import PageTitle from 'domains/Shell/PageTitle/PageTitle';
import { BillingSummaryRequestItem, CartSummaryData } from './Checkout.types';
import CheckoutPageAlert from './CheckoutPageAlert/CheckoutPageAlert';
import CheckoutRollup from './CheckoutRollup/CheckoutRollup';
import OrderSummaryCard from './OrderSummaryCard/OrderSummaryCard';
import PaymentMethodCard from './PaymentMethodCard/PaymentMethodCard';
import PaymentTermsCard from './PaymentTermsCard/PaymentTermsCard';
import TermsAndConditionsAgreementBox from './TermsAndConditionsAgreementBox/TermsAndConditionsAgreementBox';
import {
  MajorColumn,
  MinorColumn,
} from './CartSummary/CartSummaryBody/CartSummaryBody.styles';
import * as S from './Checkout.styles';

/* Checkout */
export default function Checkout() {
  const { siteId, siteName } = useAppSelector(getSiteIdentityData);
  const { data: userId } = useGetUserIdQuery(siteId);
  const skip = !userId || !siteId;

  const { cartCheckoutPaymentTerms } = useFlags();

  const { data, error, isError, isLoading } = useGetCartSummaryQuery(
    {
      siteId,
      userId,
      includePaymentPlanSummary: cartCheckoutPaymentTerms,
    },
    { skip }
  );

  useEffect(() => {
    document.title = siteName ? `${siteName}: Checkout` : 'Checkout';
  }, [siteName]);

  return (
    <CheckoutInfoProvider>
      <S.Checkout id="checkout-page">
        <PageTitle showBackButton={true} backButtonPath="/cartsummary">
          Checkout
        </PageTitle>
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>
            Something went wrong.
            <br />
            {JSON.stringify(error)}
          </div>
        ) : null}
        {data ? <CheckoutPageContent data={data} siteId={siteId} /> : null}
      </S.Checkout>
    </CheckoutInfoProvider>
  );
}
/* */

/* Checkout Page Content */
export function CheckoutPageContent({
  data,
  siteId,
}: {
  data: CartSummaryData;
  siteId: string | null;
}) {
  const { data: paymentWaiver /*, isLoading, isError, error */ } =
    useGetPaymentWaiverQuery({
      siteId,
    });

  const {
    selectedPaymentTerms,
    updateCartHasAutopayPaymentPlan,
    updateHasAutopayPaymentPlanSelected,
    updateSelectedPaymentTerms,
  } = useCheckoutInfo();

  useEffect(() => {
    const { cartItems } = data;

    const cartHasAutopayPaymentPlan = cartItems.some(
      (item) =>
        !!item.options.program.paymentPlanSummaries?.find(
          (paymentPlan) => paymentPlan.autopayRequired
        )
    );

    if (!selectedPaymentTerms) {
      const paymentTerms: BillingSummaryRequestItem[] = cartItems.map(
        (cartItem) => {
          const { paymentPlanSummaries, paymentRules } =
            cartItem.options.program;

          if (!paymentRules?.payInFull && paymentPlanSummaries?.length) {
            const firstPaymentPlan = paymentPlanSummaries[0];

            return {
              autopayRequired: firstPaymentPlan.autopayRequired,
              cartItemUuid: cartItem.cartItemUuid,
              payLater: false,
              paymentTerm: 'PAYMENT_PLAN',
              paymentPlanId: firstPaymentPlan.ngPaymentPlanId,
            };
          }

          return {
            autopayRequired: false,
            cartItemUuid: cartItem.cartItemUuid,
            payLater: false,
            paymentTerm: 'FULL',
          };
        }
      );

      const hasAutopayPaymentPlanSelected = paymentTerms.some(
        (paymentTerm) => paymentTerm.autopayRequired
      );

      updateHasAutopayPaymentPlanSelected(hasAutopayPaymentPlanSelected);
      updateSelectedPaymentTerms(paymentTerms);
    }

    updateCartHasAutopayPaymentPlan(cartHasAutopayPaymentPlan);
  }, [
    data,
    selectedPaymentTerms,
    updateCartHasAutopayPaymentPlan,
    updateHasAutopayPaymentPlanSelected,
    updateSelectedPaymentTerms,
  ]);

  return (
    <ColumnGrid>
      <CheckoutPageAlert />
      <MajorColumn>
        <OrderSummaryCard cartItems={data.cartItems} />
        <PaymentTermsCard cartItems={data.cartItems} />
        <PaymentMethodCard />
        {paymentWaiver ? (
          <TermsAndConditionsAgreementBox
            policyContent={paymentWaiver.content}
            policyName={paymentWaiver.name}
          />
        ) : null}
      </MajorColumn>
      <MinorColumn>
        <CheckoutRollup />
      </MinorColumn>
    </ColumnGrid>
  );
}
/* */
