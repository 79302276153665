"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFixedAmountPriceAdjustment = exports.isPercentagePriceAdjustment = void 0;
var isPercentagePriceAdjustment = function (priceAdjustment) {
    return ((priceAdjustment === null || priceAdjustment === void 0 ? void 0 : priceAdjustment.percentage) !== undefined);
};
exports.isPercentagePriceAdjustment = isPercentagePriceAdjustment;
var isFixedAmountPriceAdjustment = function (priceAdjustment) {
    return ((priceAdjustment === null || priceAdjustment === void 0 ? void 0 : priceAdjustment.fixedAmount) !== undefined);
};
exports.isFixedAmountPriceAdjustment = isFixedAmountPriceAdjustment;
