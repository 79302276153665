import { getStaffTeams } from 'redux/api/getStaffTeam';
import { paginatedGet } from 'domains/ActivityForm/apis/paginatedGet';
import { TeamWithProps } from 'domains/ActivityForm/utils/ActivityForm.utils';
import baseApi from './baseApi';
import { Team } from './types/team';

export const teamApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['Teams'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createTeam: builder.mutation<
        Team,
        {
          siteDomain: string;
          programId: string;
          team: Omit<Team, 'id' | 'status'>;
        }
      >({
        query: ({ siteDomain, programId, team }) => {
          return getTeamPostPayload(siteDomain, programId, team);
        },
        invalidatesTags: ['Teams'],
      }),
      updateTeam: builder.mutation<
        Team,
        { siteDomain: string; programId: string; team: Omit<Team, 'status'> }
      >({
        query: ({ siteDomain, programId, team }) => {
          return getTeamPostPayload(siteDomain, programId, team);
        },
        invalidatesTags: ['Teams'],
      }),
      deleteTeam: builder.mutation<
        void,
        {
          siteDomain: string;
          programId: string;
          editURL: string;
          teamId: string;
        }
      >({
        query: ({ siteDomain, programId, editURL, teamId }) => {
          return {
            url: `/api/member-portal/${siteDomain}/teams`,
            method: 'DELETE',
            body: {
              programId,
              editURL,
              teamId,
            },
          };
        },
        invalidatesTags: ['Teams', 'Registration'],
      }),
      getTeams: builder.query<
        Team[],
        {
          siteDomain: string;
        }
      >({
        query: ({ siteDomain }) => {
          return {
            url: `/api/member-portal/${siteDomain}/teams`,
            method: 'GET',
          };
        },
        providesTags: ['Teams'],
      }),

      getStaffTeams: builder.query({
        queryFn: async ({ userId }: { userId: number }) => {
          const data = await paginatedGet<TeamWithProps>({
            apiRequest: getStaffTeams,
            requestData: { userId },
          });
          return { data };
        },
        providesTags: ['Teams'],
      }),
      createExternalTeam: builder.mutation<
        TeamWithProps,
        {
          siteId: number;
          externalTeam: ExternalTeam;
        }
      >({
        query: ({ siteId, externalTeam }) => {
          return {
            url: `/api/sites/${siteId}/externalTeams`,
            method: 'POST',
            body: {
              ...externalTeam,
            },
          };
        },
        invalidatesTags: ['Teams'],
      }),
    }),
  });

function getTeamPostPayload(
  siteDomain: string,
  programId: string,
  team: Partial<Team>
) {
  return {
    url: `/api/member-portal/${siteDomain}/teams`,
    method: 'POST',
    body: {
      programId,
      team,
    },
  };
}

export type ExternalTeam = {
  name?: string;
  programId?: number;
};

export const {
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  useGetTeamsQuery,
  useGetStaffTeamsQuery,
  useCreateExternalTeamMutation,
} = teamApi;
