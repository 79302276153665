import { useState } from 'react';
import { Button, DownloadIcon, PrintIcon } from '@la/ds-ui-components';
import CSVDownloadModal from './CSVDownloadModal';
import * as S from './RosterManagement.styles';

export type RosterPageData = {
  programId: string;
  programType: string;
  teamId: string;
};

export function formDataBodyFromValues(
  teamId: string,
  action: string
): FormData {
  const formDataBody = new FormData();
  formDataBody.append('teamId', teamId);
  formDataBody.append('action', action);
  return formDataBody;
}

/* Roster Utilities */
export default function RosterUtilities({
  programId,
  programType,
  teamId,
}: RosterPageData) {
  const [isCSVDownloadModalVisible, setIsCSVDownlaodModalVisible] =
    useState(false);

  const formDataBody = formDataBodyFromValues(teamId, 'ExportToCSV');

  function handleCSVDownloadButtonClick(): void {
    setIsCSVDownlaodModalVisible(true);
  }

  function handlePrinterRosterButtonClick(): void {
    window.open(
      `/printable?format=HTML&type=TEAM_ROSTER&issuedByRole=Captain&teamId=${teamId}&programId=${programId}`
    );
  }

  return (
    <S.RosterUtilities>
      <Button
        leftIcon={<DownloadIcon variant="bold" size="xl" />}
        onClick={handleCSVDownloadButtonClick}
        size="large"
        variant="text"
      >
        Download roster CSV
      </Button>
      <Button
        leftIcon={<PrintIcon variant="bold" size="xl" />}
        onClick={handlePrinterRosterButtonClick}
        size="large"
        variant="text"
      >
        Print roster
      </Button>
      {isCSVDownloadModalVisible ? (
        <CSVDownloadModal
          body={formDataBody}
          closeModal={() => setIsCSVDownlaodModalVisible(false)}
          programId={programId}
          programType={programType}
        />
      ) : null}
    </S.RosterUtilities>
  );
}
/* */
