import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';

export const PageTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding: 16px 12px;
  background-color: var(--primary);
  font: var(--headline-medium);
  color: var(--white);

  svg {
    width: 28px;
    height: 28px;
    padding: 0;
    min-height: unset;
  }

  ${media.TabletLandscapeUp`
    padding: 32px 16px 16px;
    font: var(--headline-xtra-large);
    height: 90px;

    svg {
      width: 40px;
      height: 40px;
    }
  `}
`;
