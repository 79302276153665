import axios from 'axios';
import { UploadedFile } from '@la/ds-ui-components';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';

export type BaseFormField = {
  // Unique ID of the form field definition.
  propertyDefinitionId: number;
  // Unique ID of the program for this form field. May be missing/null if defined on site level.
  programId?: number;
  // Input type for the form field definition.
  // Form field definition name which can be used as the label.
  name: string;
  // Indicates whether a form field is required or optional
  usageLevel: 'REQUIRED' | 'OPTIONAL_P1';
  // Number indicating where in the list of form fields this field should appear. May not be consecutive.
  order: number;
};

export type FieldItem = { itemId: number; name: string; value: string };

export type TextFormField = BaseFormField & {
  type: 'TEXT_BOX' | 'TEXT_AREA';
  value?: string;
};

export type NumericFormField = BaseFormField & {
  type: 'NUMERIC';
  value?: number;
};

export type PickListFormField = BaseFormField & {
  type: 'PICK_LIST';
  value?: number;
  items: FieldItem[];
};

export type MultipleCheckboxesFormField = BaseFormField & {
  type: 'MULTIPLE_CHECKBOXES';
  value?: number[];
  items: FieldItem[];
};

export type FileUploadFormFieldValue = UploadedFile & { uuid?: string };
export type FileUploadFormField = BaseFormField & {
  type: 'FILE_UPLOAD';
  value?: FileUploadFormFieldValue;
};

export type FormField =
  | TextFormField
  | NumericFormField
  | PickListFormField
  | MultipleCheckboxesFormField
  | FileUploadFormField;

export type NonFileUploadFormField = Exclude<FormField, FileUploadFormField>;

export type PlayerRoles = 'captain' | 'teamPlayer' | 'freeAgent';

export type UserType = 'child' | 'adult' | 'any';

export type FormFieldParameters = {
  siteId: string;
  programId: string;
  // Comma-separated list of player roles for form fields/waivers. Defaults to all roles.
  playerRoles?: string[];
  // Comma-separated list of program staff IDs for form fields/waivers. Defaults to all program staff.
  staffRoles?: number[];
  // Flag to include deleted form fields/waivers in list, defaults to false if omitted.
  includeDeleted?: boolean;
};

export type FormFieldResponse = {
  nonFileUploadFormFields: NonFileUploadFormField[];
  fileUploadFormFields: FileUploadFormField[];
} | null;

const baseUrl = getBaseURL();

const getFormFields = async ({
  siteId,
  programId,
  playerRoles,
  staffRoles,
  includeDeleted,
}: FormFieldParameters) => {
  const endpoint = `${baseUrl}/api/sites/${siteId}/programs/${programId}/formFields`;
  const token = extractAccessToken();
  const response = await axios.get<FormField[]>(endpoint, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      playerRoles: !playerRoles ? undefined : playerRoles.join(','),
      staffRoles: !staffRoles ? undefined : staffRoles.join(','),
      userType: 'adult',
      includeDeleted,
    },
  });

  const sortedFields = response.data.sort((a, b) => a.order - b.order);

  return {
    nonFileUploadFormFields: sortedFields.filter(
      (field): field is NonFileUploadFormField => field.type !== 'FILE_UPLOAD'
    ),
    fileUploadFormFields: sortedFields.filter(
      (field): field is FileUploadFormField => field.type === 'FILE_UPLOAD'
    ),
  };
};

const getMemberFormFields = async ({
  siteId,
  userType,
  playerRoles,
  staffRoles,
}: {
  siteId: string;
  userType: UserType;
  playerRoles?: string[];
  staffRoles?: number[];
}) => {
  const endpoint = `${baseUrl}/api/sites/${siteId}/formFields/memberProfile`;
  const token = extractAccessToken();
  const response = await axios.get<FormField[]>(endpoint, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      userType: userType !== 'any' ? userType : '',
      playerRoles: playerRoles ? playerRoles.join(',') : undefined,
      staffRoles: staffRoles ? staffRoles.join(',') : undefined,
    },
  });

  return response.data.sort((a, b) => a.order - b.order);
};

const updateFormField = async ({
  siteId,
  formFieldId,
  values,
}: {
  siteId: string;
  formFieldId: string;
  values: {
    registrationId?: string;
    userId?: string;
    value: string | number;
  }[];
}) => {
  const endpoint = `${baseUrl}/api/sites/${siteId}/formFields/${formFieldId}`;
  const token = extractAccessToken();
  let formattedValues = values.map(({ registrationId, userId, value }) => ({
    registrationId: registrationId ? parseInt(registrationId) : undefined,
    userId: userId ? parseInt(userId) : undefined,
    value,
  }));
  const response = await axios.post(endpoint, formattedValues, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response;
};

export { getFormFields, getMemberFormFields, updateFormField };
