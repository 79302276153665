import styled from 'styled-components/macro';

export const PaymentTermsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const PaymentTermsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TournamentName = styled.div`
  font: var(--ui-large-regular);
`;

export const PaymentDetail = styled.div`
  font: var(--ui-large-bold);
`;
