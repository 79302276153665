import { FormEvent, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import {
  Badge,
  CaretDownIcon,
  CaretUpIcon,
  Checkbox,
  Modal,
  ModalPrimaryActionButton,
  ModalTertiaryActionButton,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Typography,
  formatAsCurrency,
} from '@la/ds-ui-components';
import { Button } from '@la/ds-ui-components';
import { RadioButton } from 'components/RadioButton/RadioButton';
import {
  PaymentPlanSummary,
  PaymentPlanSummaryInstallment,
} from '../Checkout.types';
import {
  PaymentTermsData,
  PaymentTermsItem,
} from '../PaymentTermsCard/PaymentTermsCard';
import * as S from './PaymentTermsModal.styles';

export const PAY_IN_FULL_DETAIL = 'Pay in full';
export const PAY_IN_FULL_VALUE = 'FULL';

const PAYMENT_TERMS_FORM_ID = 'paymentTerms';

export type PaymentTermsFormFields = {
  [key: string]: {
    paymentTerm: string;
    payLater?: boolean;
  };
};

export type PaymentTermsModalProps = {
  closeModal: () => void;
  onSubmit: (selections: PaymentTermsFormFields) => void;
  open: boolean;
  paymentTermsData: PaymentTermsData;
};

/* PaymentTermsModal */
export default function PaymentTermsModal({
  closeModal,
  onSubmit,
  open,
  paymentTermsData,
}: PaymentTermsModalProps) {
  const transformPaymentTermSelections = (
    formData: FormData
  ): PaymentTermsFormFields => {
    const fields: PaymentTermsFormFields = {};

    paymentTermsData.forEach(({ itemId }) => {
      const paymentTerm = formData.get(itemId);

      if (paymentTerm) {
        if (paymentTerm === 'FULL') {
          fields[itemId] = { paymentTerm };
        } else {
          const payLater = formData.get(getItemPayLaterId(itemId));
          fields[itemId] = {
            paymentTerm: paymentTerm.toString(),
            payLater: payLater?.toString() === 'true' ? true : false,
          };
        }
      }
    });

    return fields;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    onSubmit(transformPaymentTermSelections(formData));
  };

  return (
    <Modal
      onOpenChange={closeModal}
      open={open}
      primaryAction={
        <ModalPrimaryActionButton form={PAYMENT_TERMS_FORM_ID} type="submit">
          Save
        </ModalPrimaryActionButton>
      }
      tertiaryAction={
        <ModalTertiaryActionButton onClick={closeModal}>
          Cancel
        </ModalTertiaryActionButton>
      }
      size="small"
      title="Payment terms"
    >
      <S.PaymentTermsModalItems
        id={PAYMENT_TERMS_FORM_ID}
        onSubmit={handleSubmit}
      >
        {paymentTermsData.map((item, index) => (
          <PaymentTermModalItem key={index} {...item} />
        ))}
      </S.PaymentTermsModalItems>
    </Modal>
  );
}
/* */

function PaymentTermModalItem({
  itemId,
  payInFull,
  payLater,
  paymentTerm,
  paymentPlans,
  subTotal,
  tournamentName,
}: PaymentTermsItem) {
  return (
    <S.PaymentTermsModalItem>
      <S.PaymentTermsModalItemHeader>
        <Typography variant="headline" size="xs">
          {tournamentName}
        </Typography>
        <Typography variant="headline" size="xs">
          {formatAsCurrency(Number(subTotal))}
        </Typography>
      </S.PaymentTermsModalItemHeader>
      <PaymentTermModalItemPaymentPlans
        itemId={itemId}
        payInFull={payInFull}
        payLater={payLater}
        paymentPlans={paymentPlans}
        paymentTerm={paymentTerm}
      />
    </S.PaymentTermsModalItem>
  );
}

function PaymentTermModalItemPaymentPlans({
  itemId,
  payInFull,
  payLater,
  paymentPlans,
  paymentTerm,
}: {
  itemId: string;
  payInFull?: boolean;
  payLater?: boolean;
  paymentPlans: PaymentPlanSummary[];
  paymentTerm: string;
}) {
  const [selectedOption, setSelectedOption] = useState<string>(paymentTerm);

  const getCheckedState = (paymentPlan: PaymentPlanSummary): boolean => {
    return selectedOption === paymentPlan.ngPaymentPlanId;
  };

  const handlePlanOptionSelect = (paymentPlan: PaymentPlanSummary): void => {
    setSelectedOption(paymentPlan.ngPaymentPlanId);
  };

  return (
    <S.PaymentTermModalItemPaymentPlans>
      {payInFull ? (
        <S.PaymentTermsModalRow $showDivider={!!paymentPlans.length}>
          <S.PaymentTermModalItemRadioButton>
            <RadioButton
              iconColor="var(--blue-grey-600)"
              checked={selectedOption === PAY_IN_FULL_VALUE}
              handleChange={() => setSelectedOption(PAY_IN_FULL_VALUE)}
              name={itemId}
              label={PAY_IN_FULL_DETAIL}
              value={PAY_IN_FULL_VALUE}
            />
          </S.PaymentTermModalItemRadioButton>
        </S.PaymentTermsModalRow>
      ) : null}
      {paymentPlans.map((paymentPlan, index) => (
        <S.PaymentTermsModalRow
          $showDivider={index !== paymentPlans.length - 1}
          key={`${itemId}-payment-option-${index}`}
        >
          <PaymentTermModalItemRadioButton
            checked={getCheckedState(paymentPlan)}
            handleChange={() => handlePlanOptionSelect(paymentPlan)}
            itemId={itemId}
            label={paymentPlan.name}
            name={itemId}
            payLater={payLater && paymentPlan.ngPaymentPlanId === paymentTerm}
            paymentPlan={paymentPlan}
          />
        </S.PaymentTermsModalRow>
      ))}
    </S.PaymentTermModalItemPaymentPlans>
  );
}

type PaymentTermModalItemRadioButtonProps = {
  checked?: boolean;
  handleChange?: () => void;
  itemId: string;
  label: string;
  name: string;
  payLater?: boolean;
  paymentPlan: PaymentPlanSummary;
};

function PaymentTermModalItemRadioButton({
  checked,
  handleChange,
  itemId,
  label,
  name,
  payLater,
  paymentPlan,
}: PaymentTermModalItemRadioButtonProps) {
  const [isShowingDetails, setIsShowingDetails] = useState<boolean>(!!checked);

  const detailsIcon = isShowingDetails ? (
    <CaretUpIcon variant="filled" />
  ) : (
    <CaretDownIcon variant="filled" />
  );
  const detailsText = `${isShowingDetails ? 'Hide' : 'View'} details`;

  const handleSelect = (): void => {
    if (handleChange) {
      handleChange();
    }

    if (!isShowingDetails) {
      setIsShowingDetails(true);
    }
  };

  return (
    <>
      <S.PaymentTermModalItemRadioButton>
        <RadioButton
          iconColor="var(--blue-grey-600)"
          checked={checked}
          handleChange={handleSelect}
          name={name}
          label={label}
          value={paymentPlan.ngPaymentPlanId}
        />
        <PaymentPlanDetailsBadge
          numberOfPayments={paymentPlan.installments.length}
        />
        <S.PaymentTermModalItemDetailsButton>
          <Button
            hasPadding={false}
            onClick={() => setIsShowingDetails(!isShowingDetails)}
            rightIcon={detailsIcon}
            size="small"
            variant="text"
          >
            {detailsText}
          </Button>
        </S.PaymentTermModalItemDetailsButton>
      </S.PaymentTermModalItemRadioButton>
      {isShowingDetails ? (
        <PaymentPlanDetailsSchedule
          dueCheckout={paymentPlan.dueCheckout}
          isSelected={checked}
          itemId={itemId}
          installments={paymentPlan.installments}
          payLater={payLater}
        />
      ) : null}
    </>
  );
}

function PaymentPlanDetailsBadge({
  numberOfPayments,
}: {
  numberOfPayments: number;
}) {
  const paymentsText =
    numberOfPayments === 1 ? `1 payment` : `${numberOfPayments} payments`;

  return <Badge size="small" text={paymentsText} />;
}

type PaymentPlanDetailsScheduleProps = Pick<
  PaymentPlanSummary,
  'dueCheckout' | 'installments'
> & {
  isSelected?: boolean;
  itemId: string;
  payLater?: boolean;
};

function PaymentPlanDetailsSchedule({
  dueCheckout,
  isSelected,
  itemId,
  installments,
  payLater,
}: PaymentPlanDetailsScheduleProps) {
  const [currentPayLaterStatus, setCurrentPayLaterStatus] =
    useState<boolean>(!!payLater);

  useEffect(() => {
    if (!isSelected) {
      setCurrentPayLaterStatus(false);
    }
  }, [isSelected]);

  return (
    <S.PaymentPlanDetailsSchedule>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>#</TableHeader>
            <TableHeader>Amount due</TableHeader>
            <TableHeader>Date due</TableHeader>
            <TableHeader>Pay later</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {installments.map((item, index) => {
            const dueDate = DateTime.fromISO(item.installmentDate);
            const dueDateIsToday = DateTime.now()
              .startOf('day')
              .equals(dueDate.startOf('day'));

            return (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <PaymentPlanDetailsAmount
                    installmentTotal={item.installmentTotal}
                    percentage={item.percentage}
                  />
                </TableCell>
                <TableCell>
                  {dueDateIsToday || (index === 0 && !currentPayLaterStatus)
                    ? 'Today'
                    : dueDate.toFormat('MMM dd, yyyy')}
                </TableCell>
                <TableCell>
                  {index === 0 && !dueDateIsToday && !dueCheckout ? (
                    <S.PayLaterCheckbox>
                      <Checkbox
                        ariaLabel="Pay first payment later"
                        checked={currentPayLaterStatus}
                        disabled={!isSelected}
                        name={getItemPayLaterId(itemId)}
                        onCheckedChange={setCurrentPayLaterStatus}
                        id="pay-later"
                        size="large"
                        value={currentPayLaterStatus.toString()}
                      />
                    </S.PayLaterCheckbox>
                  ) : null}
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
    </S.PaymentPlanDetailsSchedule>
  );
}

function PaymentPlanDetailsAmount({
  installmentTotal,
  percentage,
}: Pick<PaymentPlanSummaryInstallment, 'installmentTotal' | 'percentage'>) {
  const formattedInstallmentTotal = formatAsCurrency(Number(installmentTotal));
  const amount = percentage
    ? `${percentage}% (${formattedInstallmentTotal})`
    : formattedInstallmentTotal;

  return <>{amount}</>;
}

function getItemPayLaterId(itemId: string): string {
  return `${itemId}-pay-later`;
}
