import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@la/ds-ui-components';
import { CardBody, CardHeader, CardTitle } from 'components/Card/Card';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';
import { MainContent } from 'domains/Shell/MainContent/MainContent';
import { useTournament } from '../Registration/hooks/useTournament';
import { TOURNAMENT_NOT_FOUND_ERROR_MESSAGE } from '../utils/errorMessages';
import { AboutCard } from './components/AboutCard/AboutCard';
import { DetailsCard } from './components/DetailsCard/DetailsCard';
import { LocationCard } from './components/LocationCard/LocationCard';
import { PageTitleSection } from './components/PageTitleSection/PageTitleSection';
import { RegisterSection } from './components/RegisterSection/RegisterSection';
import { RegistrationWindowCard } from './components/RegistrationWindowCard/RegistrationWindowCard';
import { SecondaryNavigationBar } from './components/SecondaryNavigationBar/SecondaryNavigationBar';
import { TournamentDatesCard } from './components/TournamentDatesCard/TournamentDatesCard';
import { getRegistrationPeriodStatus } from './utils/registration';
import * as S from './TournamentDetailsPage.styles';
import { RegistrationWindowCard as RegistrationWindowCardStyledComponent } from './components/RegistrationWindowCard/RegistrationWindowCard.styles';

export type RegistrationPeriodStatus =
  | {
      state: 'before-registration-period-opens';
      numberOfDays: number;
    }
  | {
      state: 'before-registration-period-ends';
      numberOfDays?: number;
    }
  | {
      state: 'after-registration-period-opens';
    }
  | {
      state: 'after-registration-period-ends';
    }
  | {
      // Used when there are divisions but there is no start or end
      // date for registration (open registration)
      state: 'no-registration-dates';
    }
  | {
      state: 'no-divisions';
    };

export function TournamentDetailsPage() {
  const { tournamentId } = useParams();

  const isTabletLandscapeUp = useMediaQuery(
    breakpointQueries.tabletLandscapeUp
  );

  const navigate = useNavigate();

  if (!tournamentId) {
    throw new Error('Tournament ID is not defined');
  }

  const { tournament, isLoading } = useTournament({ id: tournamentId });
  const [showNgAlert, setShowNgAlert] = useState(true);

  const onCloseNgAlert = () => {
    setShowNgAlert(false);
  };

  if (isLoading) {
    return <MainContent />;
  }

  if (!tournament) {
    throw new Error(TOURNAMENT_NOT_FOUND_ERROR_MESSAGE);
  }

  const {
    name,
    aboutHTML,
    gender,
    startDate,
    endDate,
    cost,
    logoURL,
    registrationStartDate,
    registrationEndDate,
    season,
    sport,
    location,
    hotelLinks,
    timeZone,
    divisions,
  } = tournament;

  const divisionNames = divisions.map((division) => division.name);
  const registrationLink = `/app/tournaments/${tournamentId}/registration`;
  const locationLink = location ? `/location/${location.id}` : undefined;

  const navigateToRegistration = () => {
    navigate(registrationLink);
  };

  const navigateToLocation = () => {
    if (locationLink) {
      navigate(locationLink);
    }
  };

  const noDivisionsRegistrationPeriodState: RegistrationPeriodStatus = {
    state: 'no-divisions',
  };
  const registrationPeriodStatus = divisions.length
    ? getRegistrationPeriodStatus(
        registrationStartDate,
        registrationEndDate,
        timeZone
      )
    : noDivisionsRegistrationPeriodState;

  const isRegisterLinkShowing = [
    'before-registration-period-ends',
    'no-registration-dates',
  ].includes(registrationPeriodStatus.state);

  const registerSection = (
    <RegisterSection
      navigateToRegistration={navigateToRegistration}
      registrationLink={registrationLink}
      registrationPeriodStatus={registrationPeriodStatus}
    />
  );

  const registrationWindowCard =
    registrationPeriodStatus.state === 'no-divisions' ? (
      <RegistrationWindowCardStyledComponent data-testid="registration-window-card">
        <CardHeader>
          <CardTitle>Registration window</CardTitle>
        </CardHeader>
        <CardBody>
          <Typography variant="ui" size="large">
            N/A
          </Typography>
        </CardBody>
      </RegistrationWindowCardStyledComponent>
    ) : (
      <RegistrationWindowCard
        navigateToRegistration={navigateToRegistration}
        registrationLink={registrationLink}
        startDate={registrationStartDate}
        endDate={registrationEndDate}
        isRegisterLinkShowing={isRegisterLinkShowing}
      />
    );

  return (
    <>
      <MainContent>
        {/* TODO: Use data from API endpoint */}
        <PageTitleSection
          logoUrl={logoURL}
          tournamentName={name}
          sport={sport}
        />
        <SecondaryNavigationBar
          hasVisibleTeams={divisions.some((division) => division.shouldDisplay)}
          registerSection={isTabletLandscapeUp ? registerSection : null}
          tournamentId={tournamentId}
        />
        <S.CardSection>
          {showNgAlert ? (
            <S.Alert>
              <S.ShootingStarIconContainer />
              <Typography variant="ui" size="large">
                Welcome to our new tournament registration experience!
              </Typography>
              <S.CloseIconClickableContainer onClick={onCloseNgAlert}>
                <S.CloseIconContainer />
              </S.CloseIconClickableContainer>
            </S.Alert>
          ) : null}
          {registrationWindowCard}
          <TournamentDatesCard startDate={startDate} endDate={endDate} />
          <LocationCard
            navigateToLocation={navigateToLocation}
            locationLink={locationLink}
            name={location?.name}
          />
          <AboutCard content={aboutHTML} />
          <DetailsCard
            divisionNames={divisionNames}
            gender={gender}
            cost={cost}
            hotelLink={hotelLinks}
            season={season}
            sport={sport}
          />
        </S.CardSection>
      </MainContent>
      {!isTabletLandscapeUp ? (
        <S.RegisterSectionContainer>
          {registerSection}
        </S.RegisterSectionContainer>
      ) : null}
    </>
  );
}
