import { DateTime } from 'luxon';
import { CardProps } from '@la/ds-ui-components';
import { formatAsCurrency } from '@la/ds-ui-components';
import { formatDateUsingShortMonthName } from 'lib/utils/dateUtils';
import { Division } from 'redux/services/types/tournament';
import { DivisionUnavailableReason } from '../../../Wizard.types';
import { DivisionCardProps } from '../DivisionCard';
import * as S from './DivisionCardHeader.styles';

export const NUMBER_OF_SPOTS_WARNING_AMOUNT = 5;

type DivisionCardHeaderProps = Pick<
  Division,
  'cost' | 'name' | 'registrationEndDate' | 'registrationStartDate'
> &
  Pick<DivisionCardProps, 'cardState'> &
  Pick<CardProps, 'variant'> & {
    cardLabelId: string;
    numberOfSpotsLeft?: number;
  };

export function DivisionCardHeader({
  cardLabelId,
  cardState,
  cost,
  name,
  numberOfSpotsLeft,
  registrationStartDate,
  registrationEndDate,
  variant,
}: DivisionCardHeaderProps) {
  let cardHeaderRightSection;
  if (cardState.value === 'unavailable') {
    cardHeaderRightSection = (
      <S.DivisionCardHeaderUnavailable>
        {getUnavailableMessage({
          reasonUnavailable: cardState.reasonUnavailable,
          registrationDates: {
            start: registrationStartDate,
            end: registrationEndDate,
          },
        })}
      </S.DivisionCardHeaderUnavailable>
    );
  } else {
    cardHeaderRightSection = (
      <div>{formatAsCurrency(cost ? Number(cost) : 0)}</div>
    );
  }

  return (
    <S.DivisionCardHeader $variant={variant}>
      <S.TitleAndSpotsLeft>
        <div id={cardLabelId}>{name}</div>
        <DivisionSpotsLeftMessage numberOfSpotsLeft={numberOfSpotsLeft} />
      </S.TitleAndSpotsLeft>
      {cardHeaderRightSection}
    </S.DivisionCardHeader>
  );
}

function DivisionSpotsLeftMessage({
  numberOfSpotsLeft,
}: {
  numberOfSpotsLeft?: number;
}) {
  if (numberOfSpotsLeft) {
    const spotsLeftText = `${
      numberOfSpotsLeft === 1 ? '1 spot' : `${numberOfSpotsLeft} spots`
    } left`;
    const message =
      numberOfSpotsLeft <= 5 ? `${spotsLeftText}!` : spotsLeftText;

    if (numberOfSpotsLeft <= 5) {
      return <S.SpotsLeftWarningMessage>{message}</S.SpotsLeftWarningMessage>;
    } else {
      return <S.SpotsLeftMessage>{message}</S.SpotsLeftMessage>;
    }
  }

  return null;
}

function getUnavailableMessage({
  reasonUnavailable,
  registrationDates,
}: {
  reasonUnavailable: DivisionUnavailableReason;
  registrationDates: {
    start?: string;
    end?: string;
  };
}) {
  switch (reasonUnavailable) {
    case 'before-registration-period-opens':
      return (
        <>
          Registration opens{' '}
          <S.RegistrationStartDate>
            {formatDateUsingShortMonthName(
              DateTime.fromISO(registrationDates.start!)
            )}
          </S.RegistrationStartDate>
        </>
      );
    case 'after-registration-period-ends':
      return 'Registration closed';
    case 'at-capacity':
      return 'At capacity';
  }

  return null;
}
