import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';

export const CartSummaryBody = styled.div``;

export const MajorColumn = styled.div`
  grid-column-start: 1;
  grid-column-end: 5;

  > * + * {
    margin-top: 12px;
  }

  ${media.TabletPortraitUp`
    grid-column-end: 9;
  `}

  ${media.TabletLandscapeUp`
    grid-column-end: 12;
  `}
`;

export const MinorColumn = styled.div`
  grid-column-start: 1;
  grid-column-end: 5;

  ${media.TabletPortraitUp`
    grid-column-start: 1;
    grid-column-end: 9;
  `}

  ${media.TabletLandscapeUp`
    grid-column-start: 12;
    grid-column-end: 17;
  `}
`;

export const EmptyCart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  padding: 24px;
`;
