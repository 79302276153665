import styled from 'styled-components';
import { breakpointConfiguration } from 'theming/grid';
export const RowContainerWrapper = styled.div.withConfig({
  componentId: "sc-1z0x7v0-0"
})(({
  $breakpoint
}) => {
  const breakpoint = $breakpoint ?? 'mobile';
  const {
    gutters,
    horizontalMargin
  } = breakpointConfiguration[breakpoint];
  return {
    margin: `0 ${horizontalMargin}`,
    padding: `${gutters} 0`
  };
});
export const GridRow = styled.div.withConfig({
  componentId: "sc-1z0x7v0-1"
})(({
  $breakpoint
}) => {
  const breakpoint = $breakpoint ?? 'mobile';
  const {
    gutters
  } = breakpointConfiguration[breakpoint];
  return {
    display: 'flex',
    flexWrap: 'wrap',
    gap: gutters,
    marginBottom: gutters,
    '&:last-of-type': {
      marginBottom: 0
    }
  };
});
export const GridColumn = styled.div.withConfig({
  componentId: "sc-1z0x7v0-2"
})(({
  $columnWidth,
  $breakpoint
}) => {
  const breakpoint = $breakpoint ?? 'mobile';
  const {
    gutters
  } = breakpointConfiguration[breakpoint];
  return {
    flexBasis: `calc(${$columnWidth}% - ${gutters})`,
    '&:last-of-type': {
      flexBasis: `${$columnWidth}%`
    }
  };
});