"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wizard = exports.DELETE_TEAM_DIVISION_ERROR_MESSAGE = exports.SUBMISSION_NO_SELECTED_DIVISIONS_ERROR_MESSAGE = exports.UPDATE_CART_ERROR_MESSAGE = exports.UPDATE_CART_LOADING_MESSAGE = exports.ADD_TO_CART_ERROR_MESSAGE = exports.ADD_TO_CART_LOADING_MESSAGE = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable */
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var ds_ui_components_1 = require("@la/ds-ui-components");
var services_1 = require("@la/services");
var ErrorCard_1 = require("../../../components/ErrorCard/ErrorCard");
var MainContent_1 = require("../../../components/MainContent/MainContent");
var hooks_1 = require("../../../lib/hooks");
var breakpoints_1 = require("../../../lib/media-queries/breakpoints");
var TeamRegistrationContext_1 = require("../TeamRegistration/TeamRegistrationContext");
var constants_1 = require("../constants");
var WizardState_1 = require("./WizardState");
var DivisionCards_1 = require("./components/DivisionCards/DivisionCards");
var SummaryAndSubmit_1 = require("./components/SummaryAndSubmit/SummaryAndSubmit");
var cart_1 = require("./components/SummaryAndSubmit/utils/cart");
var useWizardState_1 = require("./hooks/useWizardState");
var Registration_styles_1 = require("../Registration.styles");
var S = require("./Wizard.styles");
exports.ADD_TO_CART_LOADING_MESSAGE = 'We are adding your registrations to the cart.';
exports.ADD_TO_CART_ERROR_MESSAGE = 'There was an error adding your registrations. Please try adding again in a few seconds.';
exports.UPDATE_CART_LOADING_MESSAGE = 'We are updating your registrations in the cart.';
exports.UPDATE_CART_ERROR_MESSAGE = 'There was an error updating your registrations. Please try updating again in a few seconds.';
exports.SUBMISSION_NO_SELECTED_DIVISIONS_ERROR_MESSAGE = 'Please add at least one team to register.';
exports.DELETE_TEAM_DIVISION_ERROR_MESSAGE = 'This team cannot be deleted because it is registered for another tournament.';
/* Wizard */
function Wizard(_a) {
    var _this = this;
    var hasRegistrationsToEdit = _a.hasRegistrationsToEdit, sectionTitle = _a.sectionTitle, submitButtonText = _a.submitButtonText, submitUnsavedChangesErrorMessage = _a.submitUnsavedChangesErrorMessage, _b = _a.isMC, isMC = _b === void 0 ? false : _b, setWizardState = _a.setWizardState, saveDivisions = _a.saveDivisions, stepForward = _a.stepForward, tournament = _a.tournament;
    var state = (0, react_1.useContext)(TeamRegistrationContext_1.TeamRegistrationContext).state;
    var siteId = (0, react_router_dom_1.useParams)().siteId;
    var createTeam = (0, services_1.useCreateTeamMutation)()[0];
    var _c = (0, react_1.useState)(false), hasDeleteTeamDivisionError = _c[0], setHasDeleteTeamDivisionError = _c[1];
    var _d = (0, services_1.useUpdateRegistrationsMutation)(), updateRegistrations = _d[0], _e = _d[1], isUpdateRegistrationsLoading = _e.isLoading, updateRegistrationsError = _e.isError;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _f = (0, services_1.useGetTeamsQuery)({
        siteDomain: 'svssc',
        payerId: state.payer.id.toString(),
        siteId: siteId,
    }), _g = _f.data, teams = _g === void 0 ? [] : _g, isGetTeamsLoading = _f.isLoading, getTeamsError = _f.error, refetchTeams = _f.refetch;
    var initialWizardState = {
        divisionCardsState: (0, WizardState_1.getInitialDivisionCardsState)({
            divisions: tournament === null || tournament === void 0 ? void 0 : tournament.divisions,
            timeZone: tournament.timeZone,
            payerId: state.payer.id.toString(),
        }),
        submitButtonError: null,
    };
    var _h = (0, useWizardState_1.useWizardState)({
        initialState: state.wizardState
            ? {
                divisionCardsState: state.wizardState.divisionCardsState,
                submitButtonError: null,
            }
            : initialWizardState,
    }), submitButtonError = _h.submitButtonError, divisionCardsState = _h.divisionCardsState, divisionToScrollToId = _h.divisionToScrollToId, addTeamSelect = _h.addTeamSelect, submit = _h.submit, cancelDivisionChanges = _h.cancelDivisionChanges, clearDivision = _h.clearDivision, clearIdToScrollTo = _h.clearIdToScrollTo, discardTeamSelect = _h.discardTeamSelect, editDivision = _h.editDivision, saveDivision = _h.saveDivision, selectDivision = _h.selectDivision, selectTeam = _h.selectTeam;
    var assembleUpdateRegistrationsPayload = function () {
        var registrations = [];
        for (var _i = 0, _a = Object.entries(divisionCardsState); _i < _a.length; _i++) {
            var _b = _a[_i], programId = _b[0], division = _b[1];
            var registration = {
                programId: programId,
                teams: division.value === 'saved'
                    ? division.savedTeams.map(function (id) {
                        var _a, _b;
                        return ({
                            id: id,
                            name: (_b = (_a = teams.find(function (team) { return team.id === id; })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '',
                        });
                    })
                    : [],
            };
            registrations.push(registration);
        }
        return registrations;
    };
    (0, react_1.useEffect)(function () {
        if (divisionCardsState) {
            var payload = assembleUpdateRegistrationsPayload();
            var cost = (0, cart_1.calculateTotalCost)(divisionCardsState, tournament.divisions);
            setWizardState({ divisionCardsState: divisionCardsState, payload: payload, cost: cost, tournament: tournament });
        }
    }, [divisionCardsState, tournament]);
    var addTeam = function (programId, selectIndex, team) { return __awaiter(_this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, createTeam({
                    siteDomain: 'svssc',
                    programId: programId,
                    team: team,
                    payerId: state.payer.id.toString(),
                    siteId: siteId,
                })
                    .unwrap()
                    .then(function (_a) {
                    var id = _a.id;
                    refetchTeams().then(function () {
                        selectTeam(programId, selectIndex, id);
                    });
                })];
        });
    }); };
    var isTabletLandscapeUp = (0, hooks_1.useMediaQuery)(breakpoints_1.breakpointQueries.tabletLandscapeUp);
    var startDate = tournament.startDate, endDate = tournament.endDate, divisions = tournament.divisions;
    var handleSubmit = function () {
        var payload = assembleUpdateRegistrationsPayload();
        updateRegistrations({
            siteDomain: 'svssc',
            programId: tournament.id,
            editURL: "/app/tournaments/".concat(tournament.id, "/registration"),
            registrations: payload,
            payerId: state.payer.id.toString(),
            siteId: siteId,
        }).then(function () {
            if (!isMC) {
                navigate('/cartSummary');
            }
            else {
                stepForward();
            }
        });
    };
    var _j = (0, react_1.useState)(false), divisionSaved = _j[0], setDivisionSaved = _j[1];
    (0, react_1.useEffect)(function () {
        if (saveDivisions && !divisionSaved) {
            handleSubmit();
            setDivisionSaved(true);
        }
    }, [saveDivisions, handleSubmit, divisionSaved]);
    if (isGetTeamsLoading) {
        return ((0, jsx_runtime_1.jsx)(MainContent_1.MainContentCenter, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Loader, { description: "We are gathering available teams...", loading: true }) }));
    }
    if (getTeamsError) {
        return ((0, jsx_runtime_1.jsx)(MainContent_1.MainContent, { children: (0, jsx_runtime_1.jsx)(S.WizardContent, { children: (0, jsx_runtime_1.jsx)(Registration_styles_1.RegistrationErrorContainer, { children: (0, jsx_runtime_1.jsx)(ErrorCard_1.ErrorCard, { message: constants_1.API_ERROR_MESSAGE }) }) }) }));
    }
    if (isUpdateRegistrationsLoading) {
        var loadingMessage = hasRegistrationsToEdit
            ? exports.UPDATE_CART_LOADING_MESSAGE
            : exports.ADD_TO_CART_LOADING_MESSAGE;
        return ((0, jsx_runtime_1.jsx)(MainContent_1.MainContentCenter, { children: (0, jsx_runtime_1.jsx)(ds_ui_components_1.Loader, { loading: true, description: loadingMessage }) }));
    }
    var updateRegistrationsErrorMessage = hasRegistrationsToEdit
        ? exports.UPDATE_CART_ERROR_MESSAGE
        : exports.ADD_TO_CART_ERROR_MESSAGE;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(S.WizardContent, { children: [(0, jsx_runtime_1.jsxs)(S.WizardAddDivisionsAndTeamsSection, { children: [updateRegistrationsError ? ((0, jsx_runtime_1.jsx)(ErrorCard_1.ErrorCard, { message: updateRegistrationsErrorMessage })) : null, submitButtonError ? ((0, jsx_runtime_1.jsx)(ErrorCard_1.ErrorCard, { message: submitButtonError === 'unsaved-divisions'
                                    ? submitUnsavedChangesErrorMessage
                                    : exports.SUBMISSION_NO_SELECTED_DIVISIONS_ERROR_MESSAGE })) : null, hasDeleteTeamDivisionError ? ((0, jsx_runtime_1.jsx)(ErrorCard_1.ErrorCard, { message: exports.DELETE_TEAM_DIVISION_ERROR_MESSAGE })) : null, !isMC ? (0, jsx_runtime_1.jsx)(S.SectionTitle, { children: sectionTitle }) : null, (0, jsx_runtime_1.jsx)(DivisionCards_1.DivisionCards, { divisions: divisions, divisionCardsStateLookup: divisionCardsState, divisionToScrollToId: divisionToScrollToId, tournamentLocation: tournament.location, onTeamCreate: addTeam, onScrollToDivision: clearIdToScrollTo, onAddTeamSelect: addTeamSelect, onCancelDivisionChanges: cancelDivisionChanges, onClearDivision: clearDivision, onDiscardTeamSelect: discardTeamSelect, onEditDivision: editDivision, onSaveDivision: function (divisionId) {
                                    saveDivision(divisionId);
                                }, onSelectDivision: selectDivision, onSelectTeam: selectTeam, setHasDeleteTeamDivisionError: setHasDeleteTeamDivisionError, teams: teams, tournamentDates: {
                                    start: startDate,
                                    end: endDate,
                                }, tournamentId: tournament.id, refetchTeams: refetchTeams })] }), isTabletLandscapeUp ? ((0, jsx_runtime_1.jsx)(SummaryAndSubmit_1.SummaryAndSubmitLargerScreens, { divisionCardsState: divisionCardsState, onSubmissionErrors: submit, onSubmit: handleSubmit, submitButtonText: submitButtonText, teams: teams, tournament: tournament, isMC: isMC })) : null] }), !isTabletLandscapeUp ? ((0, jsx_runtime_1.jsx)(SummaryAndSubmit_1.SummaryAndSubmitSmallerScreens, { divisionCardsState: divisionCardsState, onSubmissionErrors: submit, onSubmit: handleSubmit, submitButtonText: submitButtonText, teams: teams, tournament: tournament, isMC: isMC })) : null] }));
}
exports.Wizard = Wizard;
