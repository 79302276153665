import * as S from './SubtotalReceipt.styles';

export type SubtotalReceiptProps = { subTotalPrice: string };

/* SubtotalReceipt */
export default function SubtotalReceipt({
  subTotalPrice,
}: SubtotalReceiptProps) {
  return (
    <S.SubtotalReceipt>
      <S.SubtotalHeader>
        <S.SubtotalTitle>Subtotal:</S.SubtotalTitle>
        <S.SubtotalPrice>${subTotalPrice}</S.SubtotalPrice>
      </S.SubtotalHeader>
      <S.SubtotalDescription>
        <strong>Note:</strong> The total amount due at checkout may differ from
        the above based on applied payment options, discounts and fees.
      </S.SubtotalDescription>
    </S.SubtotalReceipt>
  );
}
/* */
