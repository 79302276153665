import { createActivity } from '../apis/createActivity';
import { createAllDayActivity } from '../apis/createAllDayActivity';
import { createRecurringActivity } from '../apis/createRecurringActivity';
import { replaceActivityTeam } from '../apis/replaceActivityTeam';
import { updateActivity } from '../apis/updateActivity';
import { updateAllDayActivity } from '../apis/updateAllDayActivity';
import { updateRecurringActivities } from '../apis/updateRecurringActivities';
import {
  Activity,
  ActivityRecurrenceFrequencyUnitEnum,
  ActivityTeam,
  AllDayActivityType,
} from '../openapi-client/models';
import {
  ActivityFormAllDayActivity,
  ActivityFormData,
  RecurringActivity,
  TeamWithProps,
  getAllDayActivityRequestBody,
  getCreateActivityRequestBody,
  getHomeAwayTeamsId,
  getUpdateActivityRequestBody,
  isTeamTBD,
  processCreateRecurringActivitiesData,
  processRecurringSetActivity,
} from './ActivityForm.utils';

export const createActivityHandler = ({
  data,
  siteId,
  programId,
}: {
  data: ActivityFormData;
  siteId: number;
  programId: number;
}) => {
  if (isRecurringActivity(data)) {
    const createRecurringActivityRequest = processCreateRecurringActivitiesData(
      data,
      programId,
      siteId
    );

    return createRecurringActivity({ createRecurringActivityRequest });
  } else {
    if (isAllDayActivityForm(data)) {
      const requestData = getAllDayActivityRequestBody(data);

      const teams: ActivityTeam[] = [
        {
          teamId: parseInt(data.team, 10),
          programId: parseInt(data.subProgram, 10),
          activityId: 0,
        },
      ];

      const createAllDayActivityRequestBody = {
        programId,
        siteId,
        teams: teams,
        type: data.activityType as AllDayActivityType,
        ...requestData,
      };

      return createAllDayActivity({ createAllDayActivityRequestBody });
    } else {
      const requestData = getCreateActivityRequestBody(data, siteId, programId);

      return createActivity({ createActivityRequestBody: requestData });
    }
  }
};

export type UpdateActivityData = {
  editActivity: Activity;
  data: ActivityFormData;
  siteId: number;
  teams?: TeamWithProps[];
  isRecurringSet?: boolean;
  notify?: string;
};

export const updateActivityHandler = async ({
  editActivity,
  data,
  siteId,
  teams,
  isRecurringSet,
  notify,
}: UpdateActivityData) => {
  const activityRequestBody = getUpdateActivityRequestBody(data);

  if (isRecurringSet) {
    const recurringSetRequestData = processRecurringSetActivity(data);
    const homeTeamId = getHomeAwayTeamsId(editActivity.teams).homeTeam;
    const awayTeamId = getHomeAwayTeamsId(editActivity.teams).awayTeam;

    let locationId = editActivity.locationId;
    let subLocationId = editActivity.subLocationId;

    if (recurringSetRequestData.locationId) {
      locationId = recurringSetRequestData.locationId;
      subLocationId = recurringSetRequestData.subLocationId ?? undefined;
    }

    // update full set of recurring activity
    if (editActivity.seqToken) {
      const recurringRequestBody = {
        activityId: editActivity.id,
        locationId: locationId,
        seqToken: editActivity.seqToken,
        siteId: siteId,
        subLocationId: subLocationId,
        subProgramId: editActivity.teams[0].programId,
        teamId: [homeTeamId, awayTeamId],
        updateRecurringActivityRequestBody: {
          notify,
          ...recurringSetRequestData,
        },
      };

      return updateRecurringActivities(recurringRequestBody);
    }
  } else if (isAllDayActivityForm(data)) {
    const allDayRequestData = getAllDayActivityRequestBody(data);

    const allDayActivityRequestBody = {
      activityId: editActivity.id,
      siteId,
      updateAllDayActivityRequestBody: {
        notify,
        ...allDayRequestData,
      },
    };

    return updateAllDayActivity(allDayActivityRequestBody);
  }

  //update single activity
  const requestBody = {
    activityId: editActivity.id,
    updateActivityRequestBody: {
      siteId,
      ...activityRequestBody,
      notify,
    },
  };

  // check if home or away teams have changed
  // seperate request is needed to update teams
  const existingHomeTeamId = getHomeAwayTeamsId(editActivity?.teams).homeTeam;
  const homeTeamHasBeenChanged = data.team !== existingHomeTeamId?.toString();
  const existingAwayTeamId = getHomeAwayTeamsId(editActivity?.teams).awayTeam;
  const awayTeamHasBeenChanged =
    data.awayTeam !== existingAwayTeamId?.toString();

  let homeTeamUpdateId: number | undefined;
  if (homeTeamHasBeenChanged) {
    homeTeamUpdateId = data.team ? parseInt(data.team, 10) : undefined;
  }
  let awayTeamUpdateId: number | undefined;
  if (awayTeamHasBeenChanged) {
    awayTeamUpdateId = data.awayTeam ? parseInt(data.awayTeam, 10) : undefined;
  }

  if ((homeTeamUpdateId || awayTeamUpdateId) && teams) {
    let updateHomeTeam = false;
    let updateAwayTeam = false;

    if (homeTeamUpdateId) {
      const team = teams.find((team) => team.id === homeTeamUpdateId);
      if (team || isTeamTBD(homeTeamUpdateId)) {
        updateHomeTeam = true;
      }
    }
    if (awayTeamUpdateId) {
      const away = teams.find((team) => team.id === awayTeamUpdateId);
      if (away || isTeamTBD(awayTeamUpdateId)) {
        updateAwayTeam = true;
      }
    }

    if (updateHomeTeam || updateAwayTeam) {
      return await generateUpdateActivityTeamRequest(
        data,
        editActivity,
        siteId,
        awayTeamUpdateId,
        homeTeamUpdateId,
        notify
      ).then(() => {
        return updateActivity(requestBody);
      });
    }
  } else {
    return updateActivity(requestBody);
  }
};

const generateUpdateActivityTeamRequest = async (
  activityData: ActivityFormData,
  editActivity: Activity,
  siteId: number,
  updateAwayTeamId: number = 0,
  updateHomeTeamId?: number,
  notify?: string
) => {
  const activityId = editActivity.id;
  const newTeamProgramId = parseInt(activityData.subProgram, 10);
  //TODO: Move logic to BFF to handle two team updates

  if (updateHomeTeamId && updateAwayTeamId) {
    const oldTeamId = getHomeAwayTeamsId(editActivity.teams).homeTeam;
    const newTeamId = updateHomeTeamId;

    await replaceActivityTeam({
      siteId,
      activityId,
      replaceTeamRequestBody: {
        oldTeamId,
        newTeamId,
        newTeamProgramId,
        notify,
      },
    }).then(() => {
      const oldTeamId = getHomeAwayTeamsId(editActivity.teams).awayTeam;
      const newTeamId = updateAwayTeamId;

      return replaceActivityTeam({
        siteId,
        activityId,
        replaceTeamRequestBody: {
          oldTeamId,
          newTeamId,
          newTeamProgramId,
          notify,
        },
      });
    });
  } else if (updateHomeTeamId) {
    const oldTeamId = getHomeAwayTeamsId(editActivity.teams).homeTeam;
    const newTeamId = updateHomeTeamId;

    await replaceActivityTeam({
      siteId,
      activityId,
      replaceTeamRequestBody: {
        oldTeamId,
        newTeamId,
        newTeamProgramId,
        notify,
      },
    });
  } else {
    const oldTeamId = getHomeAwayTeamsId(editActivity.teams).awayTeam;
    const newTeamId = updateAwayTeamId;

    await replaceActivityTeam({
      siteId,
      activityId,
      replaceTeamRequestBody: {
        oldTeamId,
        newTeamId,
        newTeamProgramId,
        notify,
      },
    });
  }
};

/* Type-guard for all day activity in form */
function isAllDayActivityForm(
  data: ActivityFormData
): data is ActivityFormAllDayActivity {
  return !!data.startDate && !!data.location && data.allDay;
}

/* Type-guard for recurring activity */
function isRecurringActivity(
  data: ActivityFormData
): data is RecurringActivity {
  return (
    data.frequency === ActivityRecurrenceFrequencyUnitEnum.Day ||
    data.frequency === ActivityRecurrenceFrequencyUnitEnum.Week ||
    data.frequency === ActivityRecurrenceFrequencyUnitEnum.Month
  );
}
