import { BREAKPOINTS } from 'lib/hooks/BreakpointConstants';
import appleBadgeSVG from 'assets/badges/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import googleBadge134w from 'assets/badges/google-play-badge-134w.png';
import googleBadge200w from 'assets/badges/google-play-badge-200w.png';
import playApp250w from 'assets/play_app/play-app-250w.png';
import playApp340w from 'assets/play_app/play-app-340w.png';
import playApp378w from 'assets/play_app/play-app-378w.png';
import * as S from './PlayAppLockup.styles';

export type PlayAppLockupProps = {};

/* PlayAppLockup */
export default function PlayAppLockup(props: PlayAppLockupProps) {
  return (
    <S.PlayAppLockup>
      <S.PlayAppLockupContainer>
        <S.PlayAppLockupContent>
          <LockupImage />
          <LockupInfo />
        </S.PlayAppLockupContent>
      </S.PlayAppLockupContainer>
    </S.PlayAppLockup>
  );
}
/* */

/* Lockup Image */
export function LockupImage() {
  return (
    <S.LockupImage
      alt="LeagueApps Play App"
      srcSet={`${playApp250w} 250w, ${playApp340w} 340w, ${playApp378w} 378w`}
      sizes={`(min-width: ${BREAKPOINTS.TABLET_PORTRAIT.min}px) and (max-width: ${BREAKPOINTS.TABLET_PORTRAIT.max}px) 250px, (max-width: ${BREAKPOINTS.PHONE.max}px) 340px, (min-width: ${BREAKPOINTS.TABLET_LANDSCAPE.min}px) 378px`}
    />
  );
}
/* */

/* Lockup Info */
export function LockupInfo() {
  return (
    <S.LockupInfo>
      <S.LockupCopy>
        <S.LockupHead>
          <span>Available on every device,</span> <span>always ad-free</span>
        </S.LockupHead>
        <S.LockupBody>
          LeagueApps Play is our free team management and communications mobile
          app to view team schedules, RSVP for games and practices, get
          directions to the field, chat with coaches and parents, get alerts
          from coaches, and more.
        </S.LockupBody>
        <S.LockupBody>
          The Play app is totally free for players, parents, coaches, and staff
          to download
        </S.LockupBody>
      </S.LockupCopy>
      <AppStoreButtons />
    </S.LockupInfo>
  );
}
/* */

/* App Store Buttons */
// TODO: Get correct URLs for Apple and Google badges to link to
export function AppStoreButtons() {
  return (
    <S.AppStoreButtons>
      <a href="https://www.apple.com" target="_blank" rel="noreferrer">
        <img src={appleBadgeSVG} alt="Download on the App Store" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.leagueapps.mobile.client"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="Get it on Google Play"
          srcSet={`${googleBadge134w} 134w, ${googleBadge200w} 200w`}
          sizes={`(max-width: ${BREAKPOINTS.TABLET_PORTRAIT.max}px) 134px, (min-width: ${BREAKPOINTS.TABLET_LANDSCAPE.min}px) 200px`}
        />
      </a>
    </S.AppStoreButtons>
  );
}
/* */
