import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@la/ds-ui-components';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron_left.svg';
import * as S from './PageTitle.styles';

export type PageTitleProps = {
  showBackButton?: boolean;
  backButtonPath?: string;
};

/* PageTitle */
export default function PageTitle({
  children,
  showBackButton = false,
  backButtonPath,
}: PropsWithChildren<PageTitleProps>) {
  const navigate = useNavigate();
  return (
    <S.PageTitle>
      {showBackButton ? (
        <IconButton
          ariaLabel="Back"
          icon={<ChevronLeft />}
          onClick={() => {
            if (backButtonPath) {
              navigate(backButtonPath);
            } else {
              navigate(-1);
            }
          }}
          variant="text"
        />
      ) : null}
      {children}
    </S.PageTitle>
  );
}
/* */
