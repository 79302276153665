import styled from 'styled-components';
import {
  CardHeader,
  Snackbar,
  ThemedComponent,
  Typography,
} from '@la/ds-ui-components';
import { media } from 'lib/media-queries/mixins';

export const PlayerRegistrationContainer = styled.div`
  width: 100%;
  max-width: 920px;
`;

export const PlayerDetailsFormDescription = styled(Typography).attrs({
  size: 'medium',
  variant: 'ui',
  weight: 'medium',
})`
  margin-bottom: 16px;
`;

export const InputGroupContainer = styled.div<{ $gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 12px;

  ${({ $gap }) => {
    return media.TabletLandscapeUp`
      flex-direction: row;
      margin-bottom: ${$gap ? `${$gap}px` : '12px'}
    `;
  }}
`;

export const InputContainer = styled.div`
  flex: 1;
`;

export const TextInputContainer = styled.div`
  flex: 1;
  ${media.TabletLandscapeUp`
    padding-top: 4px;
  `}
`;

export const AddressDetailsInputGroupContainer = styled(InputGroupContainer)`
  & > div:nth-child(2) {
    flex: 0 0 25%;
  }
  & > div:nth-child(3) {
    flex: 0 0 25%;
  }
`;

export const ButtonContainer = styled.div`
  ${media.TabletLandscapeUp`
    margin-top: 28px;
    padding-bottom: 4px;
    align-self: flex-end;
  `}
`;

export const EntriesContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
});

export const FieldEntry = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

export const InfoCardHeader = styled(CardHeader)`
  display: flex;
  justify-content: space-between;
`;

export const IconContainer = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => {
    return {
      '& svg': {
        fill: theme.semantic?.secondary[500],
      },

      '&::hover': {
        cursor: 'pointer',
      },
    };
  }
);

export const EditButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const DatePickerContainer = styled.div`
  width: 100%;
  ${media.TabletLandscapeUp`
    margin-top: 28px;
  `}
`;

export const PlayerDetail = styled.div`
  display: flex;
  flex-direction: column;

  ${media.TabletPortraitUp`
    flex-direction: row;
    gap: 8px;
  `}
`;

export const PlayerDetailLabel = styled(Typography)`
  flex-basis: 30%;

  ${media.TabletPortraitUp`
    flex-basis: 35%;
    margin-bottom: 8px;    
  `}
`;
export const PlayerDetailValue = styled(Typography)`
  margin-bottom: 8px;
`;

export const PlayerDetailsSnackbar = styled(Snackbar)`
  margin-bottom: 92px;

  ${media.TabletPortraitUp`
    margin-bottom: 72px;
  `}
`;
