import { FieldItem, FormField } from 'lib/apis/getFormFields';

export const getItemLabel = (
  value: number,
  items: FieldItem[]
): string | undefined => {
  return items.find((item) => item.itemId === value)?.name;
};

export const formatItemsValue = (
  values: number[],
  items: FieldItem[]
): string => {
  const itemNames = values
    .map((value) => getItemLabel(value, items))
    .filter((value) => !value);
  return itemNames.join(',');
};

export const formatFormFieldValue = (field: FormField): string => {
  const { type, value } = field;

  if (!value) {
    return '';
  }

  switch (type) {
    case 'FILE_UPLOAD':
      return value.name;
    case 'MULTIPLE_CHECKBOXES':
      return formatItemsValue(value, field.items);
    case 'PICK_LIST':
      return getItemLabel(value, field.items) ?? '';
    default:
      return value.toString();
  }
};
