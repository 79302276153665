import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { ErrorResponse } from 'lib/apis/verifyUrlSignature';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';
import { ReplaceTeamRequestBody } from '../openapi-client/models';

const baseUrl = getBaseURL();

const replaceActivityTeam = async ({
  activityId,
  siteId,
  replaceTeamRequestBody,
}: {
  activityId: number;
  siteId: number;
  replaceTeamRequestBody: ReplaceTeamRequestBody;
}) => {
  try {
    const response = await axios.patch(
      `${baseUrl}/api/schedule/v1/activities/${activityId}/teams?siteId=${siteId}`,
      replaceTeamRequestBody,
      {
        headers: {
          Authorization: `Bearer ${extractAccessToken()}`,
          'X-Request-ID': uuidv4(),
        },
      }
    );
    return response.data;
  } catch (err) {
    const errorResponse = err as ErrorResponse;
    if (errorResponse.response) {
      if (errorResponse.response.status === 404) {
        return null;
      }
      throw errorResponse.response.data.message;
    }
    throw err;
  }
};

export { replaceActivityTeam };
