import { forwardRef, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Button,
  CaretLeftIcon,
  PlusCircleIcon,
  SelectOption,
  TextInput,
  Typography,
} from '@la/ds-ui-components';
import { getSiteIdentityData } from 'redux/coreSlice';
import { useGetSiteSettingsQuery } from 'redux/services/siteInfo';
import { useAppSelector } from 'redux/store';
import * as S from './ExternalLocationSelect.styles';

type ExternalLocationSelectProps = {
  onChange: (value?: string) => void;
  onClose: (value?: string) => void;
  locations: SelectOption[];
  value?: string;
};

export const ExternalLocationSelect = forwardRef<
  HTMLDivElement,
  ExternalLocationSelectProps
>(({ onChange, onClose, locations, value }, ref?) => {
  const { siteId } = useAppSelector(getSiteIdentityData);
  const { data: siteSettings } = useGetSiteSettingsQuery(Number(siteId), {
    skip: !siteId,
  });
  const { teamStaffCustomLocationsCreation } = useFlags();
  const allowCreateNewLocation =
    teamStaffCustomLocationsCreation && siteSettings?.appCreateLocation;

  const [showCreateExternalLocation, setShowCreateExternalLocation] =
    useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  const handleOnSelect = (location?: string) => {
    onChange(location);
    onClose(location);
  };

  const handleOnBack = (locationId?: string) => {
    setShowCreateExternalLocation(false);

    if (locationId) {
      setSelectedValue(locationId);
      onChange(locationId);
      onClose(locationId);
    }
  };

  return (
    <div>
      {!showCreateExternalLocation ? (
        <>
          {!!allowCreateNewLocation && (
            <S.ExternalLocationOptionContainer
              role="option"
              aria-selected="false"
              onClick={() => setShowCreateExternalLocation(true)}
            >
              <S.ExternalLocationOption>
                <S.ExternalLocationOptionLink>
                  <PlusCircleIcon fill="#007A00" variant="regular" /> Add a new
                  location
                </S.ExternalLocationOptionLink>
              </S.ExternalLocationOption>
            </S.ExternalLocationOptionContainer>
          )}

          {locations.map((location) => {
            return (
              <S.ExternalLocationOptionContainer
                role="option"
                aria-disabled={false}
                aria-selected={selectedValue === location.value ? true : false}
                onClick={() => handleOnSelect(location.value?.toString())}
                key={location.value}
              >
                <S.ExternalLocationOption $isDisabled={false}>
                  <Typography variant="ui" size="large" weight="regular">
                    {location.label}
                  </Typography>
                </S.ExternalLocationOption>
              </S.ExternalLocationOptionContainer>
            );
          })}
        </>
      ) : (
        <CreateExternalLocation onBack={handleOnBack} />
      )}
    </div>
  );
});

type CreateExternalLocationProps = {
  onBack: () => void;
};

export const CreateExternalLocation = forwardRef<
  HTMLDivElement,
  CreateExternalLocationProps
>(({ onBack }, ref?) => {
  return (
    <S.ExternalLocationOptionContainer
      role="option"
      aria-selected="false"
      $noHover={true}
    >
      <S.ExternalLocationOption>
        <S.ExternalLocationOptionLink onClick={() => onBack()}>
          <CaretLeftIcon fill="#007A00" variant="regular" /> Back to location
          selection
        </S.ExternalLocationOptionLink>
        <S.AddNewLocationInput>
          <TextInput
            id="external-location-name"
            label="Location name"
            placeholder="Add location name"
            required
            hasError={false}
            errorMessage=""
          />
          <TextInput
            id="external-location-address"
            label="Location address"
            placeholder="Add location address"
            required
            hasError={false}
            errorMessage=""
          />

          <div>
            <Typography variant="ui" size="medium" weight="bold">
              <S.Label>Location Description</S.Label>
            </Typography>
            <S.DescriptionTextArea
              id="notes"
              placeholder="Add location description"
              rows={3}
            />
          </div>
          <S.ButtonContainer>
            <Button size="large" onClick={() => {}}>
              Add Location
            </Button>
          </S.ButtonContainer>
        </S.AddNewLocationInput>
      </S.ExternalLocationOption>
    </S.ExternalLocationOptionContainer>
  );
});
