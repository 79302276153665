import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';

export const SubtotalReceipt = styled.div`
  padding: 0px 4px;

  ${media.TabletLandscapeUp`
    border: 1px solid var(--primary-100);
    border-radius: 4px;
    padding: 16px 24px 24px 24px;
  `}
`;

export const SubtotalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font: var(--headline-xtra-small);
  color: var(--blue-grey-900);

  ${media.TabletPortraitUp`
    margin-bottom:12px;
    padding-bottom:12px;
    border-bottom: 1px solid var(--primary-300);
  `}
`;

export const SubtotalTitle = styled.span``;

export const SubtotalPrice = styled.span``;

export const SubtotalDescription = styled.p`
  font: var(--ui-medium-medium);
  color: var(--blue-grey-900);
  margin: 0px;

  ${media.TabletLandscapeUp`
    font: var(--ui-large-medium);
  `}
`;
