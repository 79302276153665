"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewInvoices = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var ds_ui_components_1 = require("@la/ds-ui-components");
var utilities_1 = require("@la/utilities");
var TeamRegistrationContext_1 = require("../TeamRegistration/TeamRegistrationContext");
var DivisionSummaryCard_styles_1 = require("../Wizard/components/SummaryAndSubmit/DivisionSummaryCard/DivisionSummaryCard.styles");
var S = require("./ReviewInvoices.styles");
var ReviewInvoices = function () {
    var _a = (0, react_1.useContext)(TeamRegistrationContext_1.TeamRegistrationContext).state, payer = _a.payer, wizardState = _a.wizardState;
    var _b = (0, react_1.useState)(function () {
        var detailsHashMap = {};
        var divisions = wizardState.tournament.divisions.map(function (division) { return division.id; });
        divisions.forEach(function (divisionId) {
            detailsHashMap[divisionId] = false;
        });
        return detailsHashMap;
    }), showDetails = _b[0], setShowDetails = _b[1];
    var state = (0, react_1.useContext)(TeamRegistrationContext_1.TeamRegistrationContext).state;
    var payerName = "".concat(payer.firstName, " ").concat(payer.lastName);
    var divisionsToRender = state.wizardState.payload.filter(function (payload) { return payload.teams.length > 0; });
    return ((0, jsx_runtime_1.jsxs)(S.ReviewInvoicesContainer, { children: [(0, jsx_runtime_1.jsxs)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "xl" }, { children: ["Invoices will be created and sent to ", (0, jsx_runtime_1.jsx)("b", { children: payerName })] })), divisionsToRender.map(function (payload) {
                var teams = payload.teams;
                var division = state.wizardState.tournament.divisions.find(function (d) { return d.id === payload.programId; });
                return ((0, jsx_runtime_1.jsxs)(ds_ui_components_1.Card, __assign({ width: "100%" }, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.CardHeader, { children: division.name }), (0, jsx_runtime_1.jsxs)(ds_ui_components_1.CardBody, { children: [(0, jsx_runtime_1.jsxs)(S.TotalAmountContainer, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large", weight: "bold" }, { children: "Total invoice amount" })), (0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large", weight: "bold" }, { children: (0, utilities_1.formatAsUSD)(parseInt(division.cost) * teams.length) }))] }), (0, jsx_runtime_1.jsx)(ds_ui_components_1.Button, __assign({ style: { marginTop: '24px' }, variant: "outline", width: "fit-content", onClick: function () {
                                        return setShowDetails(function (previous) {
                                            var _a;
                                            return (__assign(__assign({}, previous), (_a = {}, _a[division.id] = !previous[division.id], _a)));
                                        });
                                    }, rightIcon: (0, jsx_runtime_1.jsx)(DivisionSummaryCard_styles_1.ShowAndHideButtonIcon, { "$isRotated": showDetails[division.id], variant: "bold", size: "large", fill: "#007A00" }) }, { children: showDetails ? 'Hide details' : 'Show details' })), showDetails[division.id] ? ((0, jsx_runtime_1.jsx)(S.PartialAmountContainer, { children: teams.map(function (team) {
                                        return ((0, jsx_runtime_1.jsxs)(S.PricePerTeam, { children: [(0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large" }, { children: team.name })), (0, jsx_runtime_1.jsx)(ds_ui_components_1.Typography, __assign({ variant: "ui", size: "large", weight: "bold" }, { children: division.cost }))] }, team.id));
                                    }) })) : null] })] }), payload.programId));
            })] }));
};
exports.ReviewInvoices = ReviewInvoices;
