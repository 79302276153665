import axios from 'axios';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';

export type AccountInfo = {
  user: {
    id: number;
  };
  orgAccount: {
    id: number;
    name: string;
  };
};

const baseUrl = getBaseURL();

const getAccountInfo = async ({ siteId }: { siteId: string }) => {
  const endpoint = `${baseUrl}/api/sites/${siteId}/accountInfo?context=member`;
  const token = extractAccessToken();

  const response = await axios.get<AccountInfo>(endpoint, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export { getAccountInfo };
