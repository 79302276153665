import styled from 'styled-components/macro';
import { CardHeader } from 'components/Card/Card';
import { media } from 'lib/media-queries/mixins';

export const CheckoutCardHeader = styled(CardHeader)`
  background-color: var(--primary-35);
  color: var(--blue-grey-900);

  ${media.TabletLandscapeDown`
    padding: 12px;
  `}
`;

export const CheckoutCardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 0px 12px 12px;

  ${media.TabletLandscapeUp`
    padding: 0px 16px 16px;
  `}
`;

export const CheckoutCardBody = styled.div`
  padding: 16px;
  font: var(--ui-large-regular);
  color: var(--blue-grey-900);

  ${media.DesktopUp`
    padding: 16px 24px 24px;
  `}
`;
