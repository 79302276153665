import { Root, Trigger, Content } from '@radix-ui/react-accordion';
import styled from 'styled-components';
import { generateCondensedAccordionDynamicStyles } from 'theming/tokens/components/accordion';
import { CaretDownIcon } from 'components/Icon';
const accordionHeight = {
  condensed: 0,
  outlined: '48px',
  disclosure: '32px'
};
const accordionPadding = {
  condensed: '8px 16px',
  outlined: '12px 16px',
  disclosure: '4px 16px'
};
export const AccordionWrapper = styled.div.withConfig({
  componentId: "sc-pfbmu8-0"
})(({
  $disabled
}) => {
  return {
    cursor: $disabled ? 'not-allowed' : 'auto'
  };
});
export const AccordionRoot = styled(Root).withConfig({
  componentId: "sc-pfbmu8-1"
})({
  display: 'flex',
  flexDirection: 'column',
  // 4px of margin + 1px of outline's offset
  gap: '5px'
});
export const AccordionTrigger = styled(Trigger).withConfig({
  componentId: "sc-pfbmu8-2"
})(({
  theme,
  $variant
}) => {
  const isDynamic = !!theme.semantic;
  const {
    spacing
  } = theme;
  const styles = {
    default: generateCondensedAccordionDynamicStyles(theme, 'default'),
    hover: generateCondensedAccordionDynamicStyles(theme, 'hover'),
    active: generateCondensedAccordionDynamicStyles(theme, 'active'),
    disabled: generateCondensedAccordionDynamicStyles(theme, 'disabled')
  };
  const condensedBorder = {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '1px solid'
  };
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: accordionPadding[$variant],
    border: '1px solid',
    ...($variant === 'condensed' ? condensedBorder : {}),
    borderRadius: $variant === 'condensed' ? 0 : spacing.xxxs,
    borderColor: styles.default.borderColor,
    backgroundColor: $variant === 'condensed' ? 'transparent' : styles.default.backgroundColor,
    minHeight: accordionHeight[$variant],
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      backgroundColor: $variant === 'condensed' ? 'transparent' : styles.hover.backgroundColor,
      borderColor: styles.hover.borderColor,
      '& p': {
        color: styles.hover.color
      },
      '& svg': {
        fill: styles.hover.fill,
        stroke: styles.hover.fill
      }
    },
    '&:active': {
      backgroundColor: styles.active.backgroundColor,
      '& p': {
        color: styles.active.color
      },
      '& svg': {
        fill: styles.active.fill,
        stroke: styles.active.fill
      }
    },
    '&:focus-within': {
      outline: '2px solid',
      outlineOffset: '1px',
      outlineColor: styles.default.outlineColor
    },
    '& svg': {
      willChange: 'transform',
      transitionDuration: '200ms',
      transitionProperty: 'transform',
      transform: 'rotate(0deg)',
      fill: styles.default.fill,
      stroke: styles.default.fill
    },
    '&[data-state=open]': {
      borderRadius: ['outlined', 'disclosure'].includes($variant) ? `${spacing.xxxs} ${spacing.xxxs} 0 0` : 0,
      borderBottom: isDynamic ? 'none' : undefined,
      '& svg': {
        transform: 'rotate(180deg)'
      }
    },
    '&[data-disabled]': {
      pointerEvents: 'none',
      backgroundColor: $variant === 'condensed' ? 'transparent' : styles.disabled.backgroundColor,
      borderColor: styles.disabled.borderColor,
      '& p': {
        color: styles.disabled.color
      },
      '& svg': {
        fill: styles.disabled.fill,
        stroke: styles.disabled.fill
      }
    }
  };
});
export const AccordionContent = styled(Content).withConfig({
  componentId: "sc-pfbmu8-3"
})(({
  theme,
  $variant
}) => {
  const isDynamic = !!theme.semantic;
  const {
    spacing,
    palette,
    semantic
  } = theme;
  const condensedBorder = {
    borderLeft: 'none',
    borderRight: 'none'
  };
  return {
    border: '1px solid',
    ...($variant === 'condensed' ? condensedBorder : {}),
    borderTop: 'none',
    borderColor: isDynamic ? semantic?.primary[400] : palette.foundational.blueGrey[400],
    borderRadius: $variant === 'condensed' ? 0 : `0 0 ${spacing.xxxs} ${spacing.xxxs}`,
    backgroundColor: palette.foundational.white,
    padding: $variant === 'disclosure' ? `${spacing.large} ${spacing.small}` : spacing.large
  };
});
export const AccordionCaret = styled(CaretDownIcon).attrs(() => ({
  size: 'small',
  variant: 'filled'
})).withConfig({
  componentId: "sc-pfbmu8-4"
})({});