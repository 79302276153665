import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider, BaseColors } from '@la/ds-ui-components';
import useGetRoutePaths from 'lib/hooks/useGetRoutePaths';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { setSiteInfo } from 'redux/coreSlice';
import {
  useGetSiteInfoBySubdomainQuery,
  useGetSiteThemeColorsQuery,
} from 'redux/services/siteInfo';
import { useAppDispatch } from 'redux/store';
import './App.css';

const fallbackThemeColors: BaseColors = {
  primary: '#37474F',
  secondary: '#007A00',
};

export default function App() {
  /**
   * NOTE: If the site is being hosted by and hosting deploy-previews on
   * Netlify the subdomain (containing 'la-member-portal') will not return
   * valid site info. In these cases a substitute subdomain of 'svssc' is
   * being provided instead. This is only needed for deploy-previews on
   * Netlify at this time. -DC 03/22/2023
   */
  const currentSubdomain = getLAHostnameParts().subdomain;
  const subDomain =
    currentSubdomain.indexOf('la-member-portal') > -1
      ? 'svssc'
      : currentSubdomain;

  const {
    data: siteInfoData /*,
    error: siteInfoError,
    isLoading: isSiteInfoLoading, */,
  } = useGetSiteInfoBySubdomainQuery(subDomain);
  const {
    data: themeColorsData /*,
    error: themeColorsError,
    isLoading: isThemeColorsLoading, */,
    isFetching: isThemeColorsFetching,
  } = useGetSiteThemeColorsQuery(siteInfoData?.siteId, {
    skip: !siteInfoData?.siteId,
  });
  const dispatch = useAppDispatch();

  const [themeColors, setThemeColors] = useState<BaseColors>();

  const routePaths = useGetRoutePaths();

  useEffect(() => {
    if (themeColorsData) {
      setThemeColors({
        primary: themeColorsData.theme.primaryColor,
        secondary: themeColorsData.theme.secondaryColor,
      });
    }
  }, [themeColorsData]);

  useEffect(() => {
    function updateStoreWithSiteInfo(
      logoHref: string,
      logoImageSrc: string,
      siteId: string,
      siteName: string
    ) {
      dispatch(
        setSiteInfo({
          logoHref: logoHref,
          logoImageSrc: logoImageSrc,
          siteId: siteId,
          siteName: siteName,
        })
      );
    }

    if (siteInfoData) {
      const { logoHref, logoImageSrc, siteId, siteName } = siteInfoData;
      updateStoreWithSiteInfo(logoHref, logoImageSrc, siteId, siteName);
    }
  }, [dispatch, siteInfoData]);

  return siteInfoData && !isThemeColorsFetching ? (
    <ThemeProvider baseColors={themeColors || fallbackThemeColors}>
      <RouterProvider router={routePaths} />
    </ThemeProvider>
  ) : null;
}
