import {
  createContext,
  useState,
  useContext,
  PropsWithChildren,
  useReducer,
} from 'react';
import {
  BillingSummaryRequestItem,
  PaymentMethod,
  PaymentMethodType,
} from 'domains/Checkout/Checkout.types';
import { isSingleUseCard } from 'domains/Checkout/PaymentMethodCard/PaymentMethodCard';

type CheckoutFieldErrorStates = {
  hasPaymentMethodError?: boolean;
  hasPaymentPolicyError?: boolean;
};

export type CheckoutInfo = {
  cartHasAutopayPaymentPlan: boolean;
  checkoutErrorsAreVisible: boolean;
  checkoutFieldErrorStates: CheckoutFieldErrorStates;
  hasAgreedToAutopay: boolean;
  hasAutopayPaymentPlanSelected: boolean;
  hasAgreedToPaymentPolicy: boolean;
  hasPaymentWaiver: boolean;
  selectedPaymentMethod: PaymentMethod;
  selectedPaymentTerms?: BillingSummaryRequestItem[];
  submissionErrorsAreVisible: boolean;
  updateCartHasAutopayPaymentPlan: (hasAutopayPaymentPlan: boolean) => void;
  updateCheckoutErrorsAreVisible: () => void;
  updateHasAgreedToAutopay: (checked: boolean) => void;
  updateHasAutopayPaymentPlanSelected: (selected: boolean) => void;
  updateHasAgreedToPaymentPolicy: (checked: boolean) => void;
  updateHasPaymentWaiver: (hasPaymentWaiver: boolean) => void;
  updateSelectedPaymentMethod: (paymentMethod: PaymentMethod) => void;
  updateSelectedPaymentTerms: (
    paymentTerms: BillingSummaryRequestItem[]
  ) => void;
  updateSubmissionErrorsAreVisible: (displayError: boolean) => void;
  updatePaymentMethodErrors: (displayError: boolean) => void;
  updatePaymentWaiverErrors: (displayError: boolean) => void;
};

const checkoutFieldErrorStatesDefaultValues: CheckoutFieldErrorStates = {
  hasPaymentMethodError: undefined,
  hasPaymentPolicyError: undefined,
};

export const checkoutInfoDefaultValues: CheckoutInfo = {
  cartHasAutopayPaymentPlan: false,
  checkoutErrorsAreVisible: false,
  checkoutFieldErrorStates: checkoutFieldErrorStatesDefaultValues,
  hasAgreedToAutopay: false,
  hasAutopayPaymentPlanSelected: false,
  hasAgreedToPaymentPolicy: false,
  hasPaymentWaiver: false,
  selectedPaymentMethod: null,
  selectedPaymentTerms: undefined,
  submissionErrorsAreVisible: false,
  updateCartHasAutopayPaymentPlan: (hasAutopayPaymentPlan: boolean) => {},
  updateCheckoutErrorsAreVisible: () => {},
  updateHasAgreedToAutopay: (checked: boolean) => {},
  updateHasAutopayPaymentPlanSelected: (selected: boolean) => {},
  updateHasAgreedToPaymentPolicy: (checked: boolean) => {},
  updateHasPaymentWaiver: (hasPaymentWaiver: boolean) => {},
  updateSelectedPaymentMethod: (paymentMethod: PaymentMethod | null) => {},
  updateSelectedPaymentTerms: (paymentTerms: BillingSummaryRequestItem[]) => {},
  updateSubmissionErrorsAreVisible: (displayError: boolean) => {},
  updatePaymentMethodErrors: (displayError: boolean) => {},
  updatePaymentWaiverErrors: (displayError: boolean) => {},
};

function checkoutErrorsReducer(
  checkoutFieldErrorStates: CheckoutFieldErrorStates,
  { type, hasError }: { type: 'method' | 'policy'; hasError: boolean }
) {
  switch (type) {
    case 'method':
      return {
        ...checkoutFieldErrorStates,
        hasPaymentMethodError: hasError,
      };
    case 'policy':
      return {
        ...checkoutFieldErrorStates,
        hasPaymentPolicyError: hasError,
      };
    default:
      return checkoutFieldErrorStates;
  }
}

const CheckoutInfoContext = createContext<CheckoutInfo>(
  checkoutInfoDefaultValues
);

const CheckoutInfoProvider = ({ children }: PropsWithChildren) => {
  const [cartHasAutopayPaymentPlan, setCartHasAutopayPaymentPlan] =
    useState<boolean>(checkoutInfoDefaultValues.cartHasAutopayPaymentPlan);
  const [checkoutErrorsAreVisible, setCheckoutErrorsAreVisible] =
    useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(
      checkoutInfoDefaultValues.selectedPaymentMethod
    );
  const [selectedPaymentTerms, setSelectedPaymentTerms] = useState<
    BillingSummaryRequestItem[] | undefined
  >(checkoutInfoDefaultValues.selectedPaymentTerms);
  const [hasPaymentWaiver, setHasPaymentWaiver] = useState<boolean>(
    checkoutInfoDefaultValues.hasPaymentWaiver
  );
  const [submissionErrorsAreVisible, setSubmissionErrorsAreVisible] =
    useState<boolean>(checkoutInfoDefaultValues.submissionErrorsAreVisible);
  const [hasAutopayPaymentPlanSelected, setHasAutopayPaymentPlanSelected] =
    useState<boolean>(checkoutInfoDefaultValues.hasAutopayPaymentPlanSelected);
  const [hasAgreedToAutopay, setHasAgreedToAutopay] = useState<boolean>(
    checkoutInfoDefaultValues.hasAgreedToAutopay
  );
  const [hasAgreedToPaymentPolicy, setHasAgreedToPaymentPolicy] =
    useState<boolean>(checkoutInfoDefaultValues.hasAgreedToPaymentPolicy);
  const [checkoutFieldErrorStates, dispatch] = useReducer(
    checkoutErrorsReducer,
    checkoutInfoDefaultValues.checkoutFieldErrorStates
  );

  function updateCartHasAutopayPaymentPlan(hasAutopayPaymentPlan: boolean) {
    setCartHasAutopayPaymentPlan(hasAutopayPaymentPlan);
  }

  function updateCheckoutErrorsAreVisible() {
    setCheckoutErrorsAreVisible(true);
  }

  function updateHasAgreedToAutopay(hasAgreed: boolean) {
    setHasAgreedToAutopay(hasAgreed);
    if (hasAutopayPaymentPlanSelected) {
      updatePaymentMethodErrors(!hasAgreed);
    } else {
      updatePaymentMethodErrors(false);
    }
  }

  function updateHasAutopayPaymentPlanSelected(selected: boolean) {
    setHasAutopayPaymentPlanSelected(selected);
    updatePaymentMethodErrors(
      selectedPaymentMethod
        ? !selectedPaymentMethod.paymentMethodId && selected
        : false
    );
  }

  function updateHasAgreedToPaymentPolicy(hasAgreed: boolean) {
    setHasAgreedToPaymentPolicy(hasAgreed);
    updatePaymentWaiverErrors(!hasAgreed);
  }

  function updateSelectedPaymentMethod(paymentMethod: PaymentMethod) {
    setSelectedPaymentMethod(paymentMethod);
    updatePaymentMethodErrors(
      !paymentMethod ||
        (!paymentMethod.paymentMethodId && hasAutopayPaymentPlanSelected)
    );
  }

  function updateSelectedPaymentTerms(
    paymentTerms: BillingSummaryRequestItem[]
  ) {
    setSelectedPaymentTerms(paymentTerms);
  }

  function updatePaymentMethodErrors(displayError: boolean) {
    dispatch({ type: 'method', hasError: displayError });
  }

  function updateHasPaymentWaiver(hasWaiver: boolean) {
    setHasPaymentWaiver(hasWaiver);
  }

  function updatePaymentWaiverErrors(displayError: boolean) {
    dispatch({ type: 'policy', hasError: displayError });
  }

  function updateSubmissionErrorsAreVisible(displayError: boolean) {
    setSubmissionErrorsAreVisible(displayError);
  }

  return (
    <CheckoutInfoContext.Provider
      value={{
        cartHasAutopayPaymentPlan: cartHasAutopayPaymentPlan,
        checkoutErrorsAreVisible: checkoutErrorsAreVisible,
        checkoutFieldErrorStates: checkoutFieldErrorStates,
        hasAgreedToAutopay: hasAgreedToAutopay,
        hasAutopayPaymentPlanSelected: hasAutopayPaymentPlanSelected,
        hasAgreedToPaymentPolicy: hasAgreedToPaymentPolicy,
        hasPaymentWaiver: hasPaymentWaiver,
        selectedPaymentMethod: selectedPaymentMethod,
        selectedPaymentTerms: selectedPaymentTerms,
        submissionErrorsAreVisible: submissionErrorsAreVisible,
        updateCartHasAutopayPaymentPlan: updateCartHasAutopayPaymentPlan,
        updateCheckoutErrorsAreVisible: updateCheckoutErrorsAreVisible,
        updateHasAgreedToAutopay: updateHasAgreedToAutopay,
        updateHasAutopayPaymentPlanSelected:
          updateHasAutopayPaymentPlanSelected,
        updateHasAgreedToPaymentPolicy: updateHasAgreedToPaymentPolicy,
        updateHasPaymentWaiver: updateHasPaymentWaiver,
        updateSelectedPaymentMethod: updateSelectedPaymentMethod,
        updateSelectedPaymentTerms: updateSelectedPaymentTerms,
        updateSubmissionErrorsAreVisible: updateSubmissionErrorsAreVisible,
        updatePaymentMethodErrors: updatePaymentMethodErrors,
        updatePaymentWaiverErrors: updatePaymentWaiverErrors,
      }}
    >
      {children}
    </CheckoutInfoContext.Provider>
  );
};

const useCheckoutInfo = () => {
  const {
    cartHasAutopayPaymentPlan,
    hasAgreedToAutopay,
    hasAutopayPaymentPlanSelected,
    hasAgreedToPaymentPolicy,
    hasPaymentWaiver,
    selectedPaymentMethod,
    selectedPaymentTerms,
    submissionErrorsAreVisible,
    updateCartHasAutopayPaymentPlan,
    updateCheckoutErrorsAreVisible,
    updateHasPaymentWaiver,
    updateHasAgreedToAutopay,
    updateHasAutopayPaymentPlanSelected,
    updateHasAgreedToPaymentPolicy,
    updateSelectedPaymentMethod,
    updateSelectedPaymentTerms,
    updatePaymentMethodErrors,
    updatePaymentWaiverErrors,
    updateSubmissionErrorsAreVisible,
    checkoutFieldErrorStates,
    checkoutErrorsAreVisible,
  } = useContext<CheckoutInfo>(CheckoutInfoContext);

  const selectedPaymentMethodType: PaymentMethodType =
    selectedPaymentMethod?.paymentType ?? null;

  const handleHasAgreedToPaymentPolicyChange = (checked: boolean) => {
    updateHasAgreedToPaymentPolicy(checked);
  };

  const handleSelectedPaymentMethodChange = (
    paymentMethod: PaymentMethod | null
  ) => {
    if (paymentMethod !== selectedPaymentMethod) {
      updateSelectedPaymentMethod(paymentMethod);
      updatePaymentMethodErrors(!paymentMethod);
    }
  };

  function validatePaymentMethod(dueToday?: string) {
    const autopayAgreementValid = hasAutopayPaymentPlanSelected
      ? hasAgreedToAutopay && !isSingleUseCard(selectedPaymentMethod)
      : true;
    const paymentMethodNotNeeded: boolean =
      !!dueToday &&
      parseFloat(dueToday) === 0 &&
      !hasAutopayPaymentPlanSelected;
    return (
      (!!selectedPaymentMethod && autopayAgreementValid) ||
      paymentMethodNotNeeded
    );
  }

  function validatePaymentWaiver() {
    if (hasPaymentWaiver) {
      updateHasAgreedToPaymentPolicy(hasAgreedToPaymentPolicy);
      return hasAgreedToPaymentPolicy;
    }

    return true;
  }

  function validateCheckout(dueToday?: string): {
    methodIsValid: boolean;
    waiverIsValid: boolean;
  } {
    const methodIsValid = validatePaymentMethod(dueToday);
    const waiverIsValid = validatePaymentWaiver();
    return { methodIsValid, waiverIsValid };
  }

  function updateErrorStates(
    displayMethodError: boolean,
    displayWaiverError: boolean
  ) {
    updatePaymentMethodErrors(displayMethodError);
    updatePaymentWaiverErrors(displayWaiverError);
  }

  return {
    validateCheckout: validateCheckout,
    updateErrorStates: updateErrorStates,
    cartHasAutopayPaymentPlan: cartHasAutopayPaymentPlan,
    checkoutFieldErrorStates: checkoutFieldErrorStates,
    checkoutErrorsAreVisible: checkoutErrorsAreVisible,
    hasAgreedToAutopay: hasAgreedToAutopay,
    hasAutopayPaymentPlanSelected: hasAutopayPaymentPlanSelected,
    hasAgreedToPaymentPolicy: hasAgreedToPaymentPolicy,
    hasPaymentWaiver: hasPaymentWaiver,
    selectedPaymentMethod: selectedPaymentMethod,
    selectedPaymentMethodType: selectedPaymentMethodType,
    selectedPaymentTerms: selectedPaymentTerms,
    submissionErrorsAreVisible: submissionErrorsAreVisible,
    updateCartHasAutopayPaymentPlan: updateCartHasAutopayPaymentPlan,
    updateCheckoutErrorsAreVisible: updateCheckoutErrorsAreVisible,
    updateHasAgreedToAutopay: updateHasAgreedToAutopay,
    updateHasAutopayPaymentPlanSelected: updateHasAutopayPaymentPlanSelected,
    updateHasAgreedToPaymentPolicy: updateHasAgreedToPaymentPolicy,
    updateHasPaymentWaiver: updateHasPaymentWaiver,
    handleHasAgreedToPaymentPolicyChange: handleHasAgreedToPaymentPolicyChange,
    updateSelectedPaymentMethod: handleSelectedPaymentMethodChange,
    updateSelectedPaymentTerms: updateSelectedPaymentTerms,
    updateSubmissionErrorsAreVisible: updateSubmissionErrorsAreVisible,
  };
};

export { CheckoutInfoContext, CheckoutInfoProvider, useCheckoutInfo };
