import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormFieldResponse } from 'lib/apis/getFormFields';

const formSteps: Record<string, string> = {
  'basic-info': 'nonFileUploadFormFields',
  'upload-documents': 'fileUploadFormFields',
};

export const playerStepDescription: Record<string, string> = {
  'basic-info': 'Provide more info about your player',
  'upload-documents': 'Upload required documents',
  waivers: 'Sign required documents',
  review: 'Review your registration',
};

export const staffStepDescription: Record<string, string> = {
  'basic-info': 'Provide more information about you and your team',
  'upload-documents': 'Upload required documents',
  waivers: 'Sign required documents',
  review: 'Review your registration',
};

export const initialSteps: Record<string, Record<string, string>> = {
  player: playerStepDescription,
  staff: staffStepDescription,
};

export const getSteps = (
  type: 'staff' | 'player',
  formFields: FormFieldResponse
) => {
  if (type === 'player') {
    if (formFields?.fileUploadFormFields.length === 0) {
      const steps = { ...playerStepDescription };
      delete steps['upload-documents'];
      return steps;
    }
    return playerStepDescription;
  }
  if (type === 'staff') {
    const steps = { ...staffStepDescription };

    if (formFields?.nonFileUploadFormFields.length === 0) {
      delete steps['basic-info'];
    }
    if (formFields?.fileUploadFormFields.length === 0) {
      delete steps['upload-documents'];
    }
    return steps;
  }
  return {};
};

export const getCurrentStep = (
  type: 'staff' | 'player',
  formFields: FormFieldResponse
) => {
  const steps = getSteps(type, formFields);

  const path = window.location.pathname.split('/').at(-1);
  if (path === 'registration' || path === 'registration/') {
    if (steps['basic-info']) {
      return 'basic-info';
    }
    if (steps['upload-documents']) {
      return 'upload-documents';
    }
    return 'waivers';
  }
  return path ?? '';
};

export const useRegistrantSteps = (
  type?: 'staff' | 'player',
  formFields?: FormFieldResponse
) => {
  const navigate = useNavigate();

  if (!type || !formFields) {
    throw new Error('No type and form fields were provided');
  }
  const steps = getSteps(type, formFields);
  const currentStep = getCurrentStep(type, formFields);

  const stepNumber =
    Object.keys(steps).findIndex((key) => key === currentStep) + 1;
  const numberOfTotalSteps = Object.keys(steps).length;

  const getNextStep = () => {
    if (stepNumber + 1 > numberOfTotalSteps) {
      return 'success';
    }
    return Object.keys(steps).find((_, i) => i === stepNumber) as string;
  };

  const getPreviousStep = () => {
    if (currentStep === 'success') {
      return 'review';
    }
    return Object.keys(steps).find((_, i) => i === stepNumber - 2) as string;
  };

  useEffect(() => {
    const path = window.location.pathname.split('/').at(-1);
    let initialStep = 'basic-info';
    // We only need to skip basic-info step in staff registration
    if (type === 'staff' && path === 'registration' && !steps['basic-info']) {
      if (steps['upload-documents']) {
        initialStep = 'upload-documents';
      } else {
        // Waivers step is guaranteed to exist
        initialStep = 'waivers';
      }

      if (initialStep === 'basic-info') {
        navigate(`/app/registration`);
      } else {
        navigate(`/app/registration/${initialStep}`);
      }
    }
  }, [navigate, steps, type]);

  const nextStep = getNextStep();
  const previousStep = getPreviousStep();

  const onNextClick = () => {
    navigate(`/app/registration/${nextStep}`);
  };

  const onBackClick = () => {
    if (previousStep === 'basic-info') {
      navigate('/app/registration');
    } else {
      navigate(`/app/registration/${previousStep}`);
    }
  };

  return {
    steps,
    currentStep,
    getNextStep,
    getPreviousStep,
    formSteps,
    stepNumber,
    numberOfTotalSteps,
    onNextClick,
    onBackClick,
  };
};
