import { http, HttpResponse } from 'msw';
import { Team } from 'redux/services/types/team';

export enum TeamEndpoint {
  PostTeam = '*/api/member-portal/*/teams',
  DeleteTeam = '*/api/member-portal/*/teams',
  GetTeams = '*/api/member-portal/*/teams',
}

export const mockTeams: Team[] = [
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    name: 'Hawks',
    city: 'Shrewsbury',
    country: 'USA',
    organization: 'Birds',
    status: 'DRAFT',
    ageGroup: 8,
    teamRepresentative: {
      name: 'Haley Smith',
      phoneNumber: '1234567890',
      email: 'haley@smith.com',
    },
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa7',
    name: 'Falcons',
    city: 'Shrewsbury',
    country: 'USA',
    organization: 'Birds',
    status: 'DRAFT',
    ageGroup: 8,
    teamRepresentative: {
      name: 'Christopher Connor',
      phoneNumber: '1234567890',
      email: 'christopher.connor@example.com',
    },
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa8',
    name: 'Eagles',
    city: 'Shrewsbury',
    country: 'USA',
    organization: 'Birds',
    status: 'DRAFT',
    ageGroup: 9,
    teamRepresentative: {
      name: 'Karen Munn',
      phoneNumber: '1234567890',
      email: 'karenmunn@fakeemail.com',
    },
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afb9',
    name: 'Tigers',
    city: 'Shrewsbury',
    country: 'USA',
    organization: 'Birds',
    status: 'DRAFT',
    ageGroup: 0,
    teamRepresentative: {
      name: 'Karen Munn',
      phoneNumber: '1234567890',
      email: 'karenmunn@fakeemail.com',
    },
  },
];

// For unit tests, using IDs that are easy to read when debugging
export const mockTeamsForUnitTests = mockTeams.map((team, index) => ({
  ...team,
  id: `team-${index + 1}`,
}));

export const teamHandlers = [
  http.post<{}, Omit<Team, 'id'> & { id?: string }>(
    TeamEndpoint.PostTeam,
    async ({ request }) => {
      const team: Omit<Team, 'id'> & { id?: string } = await request.json();

      return HttpResponse.json(
        team.id ? team : { ...team, id: 'mock-team-123' },
        { status: 200 }
      );
    }
  ),
  http.delete(TeamEndpoint.DeleteTeam, async () => {
    return new HttpResponse(null, { status: 204 });
  }),
  http.get(TeamEndpoint.GetTeams, () => {
    return HttpResponse.json(mockTeamsForUnitTests, { status: 200 });
  }),
];
