import { useState } from 'react';
import {
  Button,
  Card,
  CardProps,
  Typography,
  formatAsCurrency,
} from '@la/ds-ui-components';
import { Team } from 'redux/services/types/team';
import { Division } from 'redux/services/types/tournament';
import { getDivisionCost } from 'domains/Tournaments/TournamentDetailsPage/utils/cost';
import { getDivisionSubtotal } from './DivisionSummaryCard.utils';
import * as S from './DivisionSummaryCard.styles';

const TEAMS_TABLE_COLUMN_HEADERS: string[] = ['Team #', 'Team name'];

export type DivisionSummaryCardProps = {
  division: Division;
  teams: Team[];
};

export function DivisionSummaryCard({
  division,
  teams,
}: DivisionSummaryCardProps) {
  const subtotal = getDivisionSubtotal(getDivisionCost(division), teams.length);
  const cardId = getDivisionSummaryCardId(division);

  const variant: CardProps['variant'] = 'dense';

  /*
   * This ID is used to associate the division name as the label for interactive content
   * inside the card so that assistive technologies will announce the division name when
   * a user goes directly to the show/hide button.
   */
  const cardLabelId = `${cardId}-label`;

  // This ID used to associate the disclosure button with the content it shows / hides
  const teamsListId = `division-${division.id}-summary-teams-list`;

  const [isShowingTeams, setIsShowingTeams] = useState(false);
  const toggleIsShowingTeams = () => setIsShowingTeams(!isShowingTeams);

  return (
    <div aria-labelledby={cardLabelId} role="group" data-testid={cardId}>
      <Card variant={variant}>
        <S.DivisionSummaryCardHeader>
          <S.DivisionSummaryCardHeaderName id={cardLabelId}>
            {division.name}
          </S.DivisionSummaryCardHeaderName>
          {teams.length > 0 ? <div>{formatAsCurrency(subtotal)}</div> : null}
        </S.DivisionSummaryCardHeader>
        <DivisionSummaryCardMainSection
          isTeamsListShowingTeams={isShowingTeams}
          handleToggleButtonClick={toggleIsShowingTeams}
          teams={teams}
          teamsListId={teamsListId}
          variant={variant}
        />
        {isShowingTeams ? (
          <DivisionSummaryCardTeamsListSection teams={teams} id={teamsListId} />
        ) : null}
      </Card>
    </div>
  );
}

type DivisionSummaryCardTeamsListProps = {
  teams: Team[];
  id: string;
};

function DivisionSummaryCardTeamsListSection({
  teams,
  id,
}: DivisionSummaryCardTeamsListProps) {
  return (
    <S.DivisionSummaryCardTeamsTableContainer>
      <S.DivisionSummaryCardTeamsTable id={id}>
        <thead>
          <tr>
            {TEAMS_TABLE_COLUMN_HEADERS.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {teams.map(({ id: teamId, name }, index) => (
            <tr key={teamId}>
              <td>{`Team ${index + 1}`}</td>
              <td>{name}</td>
            </tr>
          ))}
        </tbody>
      </S.DivisionSummaryCardTeamsTable>
    </S.DivisionSummaryCardTeamsTableContainer>
  );
}

type DivisionSummaryCardMainSectionProps = Pick<CardProps, 'variant'> & {
  isTeamsListShowingTeams: boolean;
  handleToggleButtonClick: () => void;
  teams: Team[];
  teamsListId: string;
};

function DivisionSummaryCardMainSection({
  isTeamsListShowingTeams,
  teams,
  teamsListId,
  variant,
  handleToggleButtonClick,
}: DivisionSummaryCardMainSectionProps) {
  const numberOfTeams = teams.length;
  if (numberOfTeams === 0) {
    return (
      <S.DivisionSummaryCardMainSection $variant={variant}>
        <Typography size="large" variant="ui">
          You have <b>not</b> saved any teams to this division yet
        </Typography>
      </S.DivisionSummaryCardMainSection>
    );
  } else {
    return (
      <S.DivisionSummaryCardMainSection $variant={variant}>
        <Typography size="large" variant="ui">
          {getTeamsMessage(numberOfTeams)}
        </Typography>
        <Button
          aria-controls={teamsListId}
          aria-expanded={isTeamsListShowingTeams}
          leftIcon={
            <S.ShowAndHideButtonIcon $isRotated={isTeamsListShowingTeams} />
          }
          onClick={handleToggleButtonClick}
          variant="outline"
          width="fit-content"
        >
          {isTeamsListShowingTeams ? 'Hide teams' : 'Show teams'}
        </Button>
      </S.DivisionSummaryCardMainSection>
    );
  }
}

function getTeamsMessage(numberOfTeams: number) {
  if (numberOfTeams === 1) {
    return (
      <>
        <b>1 team</b> added
      </>
    );
  } else {
    return (
      <>
        <b>{numberOfTeams} teams</b> added
      </>
    );
  }
}

export function getDivisionSummaryCardId(division: Division) {
  return `reg-wizard-division-summary-card-${division.id}`;
}
